const nl = {
    code: "NL",
    name: "Nederlands",
    select_locale: "Kies Nederlands als taal",

    header: {
        title: "Research Access Management",
        links: {
            login: "Inloggen",
            system: "Systeem",
            impersonate: "Imiteer",
            createCollaboration: "Nieuwe samenwerking",
            requestCollaboration: "Samenwerking aanvragen",
            requestService: "Applicatie registreren",
            help: "Help",
            profile: "Profiel",
            logout: "Uitloggen",
            feedback: "Geef feedback",
            helpUrl: "https://edu.nl/vw3jx"
        },
    },
    breadcrumb: {
        home: "Home",
        registration: "Registratie",
        collaborations: "Samenwerkingen",
        collaboration: "{{name}}",
        collaborationJoinRequest: "Lidmaatschapsverzoek voor '{{name}}'",
        organisations: "Organisaties",
        organisation: "{{name}}",
        collaborationRequest: "Samenwerkingsaanvraag '{{name}}'",
        services: "Applicaties",
        service: "{{name}}",
        serviceRequest: "Applicatieregistratie",
        group: "Groep '{{name}}'",
        newGroup: "Nieuwe groep",
        profile: "Profiel",
        aup: "AUP",
        history: "Geschiedenis",
        system: "Systeem",
        confirmation: "Bevestiging van een beheerder",
        newOrganisation: "Nieuwe organisatie",
        requestService: "Applicatie registreren",
        newService: "Nieuwe applicatie",
        newCollaboration: "Nieuwe samenwerking",
        newCollaborationRequest: "Nieuw samenwerkingsverzoek",
        editCollaboration: "Wijzig samenwerking",
        impersonate: "Imitatie",
        organisationInvite: "Beheerders uitnodigen",
        organisationInvitation: "Uitnodiging",
        serviceInvite: "Beheerders uitnodigen",
        serviceInvitation: "Uitnodiging",
        invite: "Leden toevoegen",
        invitation: "Uitnodiging",
        users: "Gebruikers",
        userToken: "Applicatietoken '{{name}}'",
        myRequests: "Mijn verzoeken"
    },
    login: {
        title: "Research Access Management",
        subTitle: "Inloggen...",
        suspended: "Je profiel is geschorst. Neem contact op sram-support@surf.nl om dit op te lossen",
        rateLimited: "Je hebt de TOTP-verificatie te vaak geprobeerd en daarom ben je uitgelogd en is je TOTP uitgeschakeld. Neem contact op met uw beheerder om dit op te lossen",
        successfullyActivated: "Gelukt: je profiel is geheractiveerd",
        closeBrowser: "Om volledig uit te loggen, moet je je browser sluiten",
        closeBrowserAfterDelete: "Om het verwijderen van je profiel te voltooien, moet je je browser sluiten."
    },
    home: {
        sram: "SURF Research Access Management",
        edit: "Wijzigen",
        history: "Geschiedenis",
        historyLink: "Bekijk geschiedenis",
        unsuspend: "Schorsing ongedaan maken",
        avoidSuspending: "Voorkom schorsing",
        resetLastActivity: "Reset activiteit",
        otherOptions: "Meer opties",
        details: "Details",
        backToHome: "Terug naar home",
        firstTime: "Onboarding",
        collaborationRequest: "Vraag een nieuwe samenwerking aan",
        noOrganisations: "Om een nieuwe samenwerking te kunnen aanvragen, moet de instelling '{{schac_home}}' waarmee je bent ingelogd dat mogen. Dat lijkt nog niet het geval. Neem contact op <a href='mailto:sram-support@surf.nl'>sram-support@surf.nl</a> voor meer informatie.",
        noShacHome: "Om een nieuwe samenwerking aan te vragen of aan te maken, dient je instelling het attribuut schacHomeOrganization vrij te geven. Dit lijkt niet het geval te zijn. Neem voor meer informatie contact op via <a href='mailto:sram-support@surf.nl'> sram-support@surf.nl </a>",
        noOrganisationsPlatformAdmin: "Om een nieuwe samenwerking te maken dien je eerst een nieuwe organisatie aan te maken.",
        tabs: {
            groups: "Groepen",
            collaborations: "Samenwerkingen",
            organisations: "Organisaties",
            services: "Applicaties",
            platformAdmins: "Platformbeheerders",
            orgAdmins: "Org.beheerders",
            orgServices: "Applicaties",
            serviceAdmins: "Beheerders",
            orgCollaborations: "Samenwerkingen",
            coServices: "Applicaties",
            coAdmins: "Beheerders",
            members: "Leden",
            users: "Gebruikers",
            serviceOrganisations: "Toegangsregels",
            serviceCollaborations: "Gekoppelde samenwerkingen",
            userTokens: "Applicatietokens",
            collaborationRequests: "Verzoeken",
            joinRequests: "Lidmaatschapsverzoeken",
            myRequests: "Mijn verzoeken",
            serviceRequests: "Applicatieregistratie",
            serviceConnectionRequests: "Koppelverzoeken",
            me: "Je details",
            about: "Over",
            apikeys: "API-tokens",
            welcome: "Welkom",
            cron: "Cron",
            seed: "Seed",
            database: "Database",
            activity: "Activiteit",
            validation: "Acties",
            plsc: "LDAP",
            composition: "Config",
            suspendedUsers: "Users",
            userlogins: "Logins",
            details: "Details & configuratie",
            orgUsers: "Gebruikers",
            scim: "SCIM",
            stats: "Stats",
            pam: "PAM"
        }
    },
    forms: {
        submit: "Toevoegen",
        save: "Opslaan",
        apply: "Toepassen",
        ignore: "Negeer wijzigingen",
        request: "Aanvragen",
        cancel: "Annuleren",
        close: "Sluiten",
        showMore: "Meer",
        hideSome: "Minder",
        open: "Open",
        update: "Wijzigen",
        today: "Vandaag",
        manage: "Beheren",
        invalidInput: "Ongeldig{{name}}",
        invalidRedirectUrl: "Niet-localhost redirect URL's moeten het https protocol hebben",
        attributes: {
            email: " e-mailadres",
            uri: "e URL (begin met bijvoorbeeld 'https://')",
            contact: " contact (een e-mailadres of een URL)"
        },
        back: "Terug",
        reset: "Herstel",
        yes: "Ja",
        upload: "Upload afbeelding",
        uploadSSH: "Voeg SSH-sleutel toe",
        add: "Voeg een afbeelding toe",
        change: "Wijzig afbeelding",
        image: "Afbeelding moet kleiner zijn dan 2MB.",
        dragImage: "Versleep de rechthoek en pas de grootte aan totdat je tevreden bent met het resultaat. Kies vervolgens voor toepassen",
        imageToLarge: "Afbeelding is groter dan 2MB",
        imageDeleteConfirmation: "Ben je zeker dat je deze afbeelding wil verwijderen? Dit kan niet worden teruggedraaid. Hierna zul je een nieuwe afbeelding moeten toevoegen.",
        imageRequired: "Afbeelding is verplicht",
        whiteSpace: "Afbeelding passend maken",
        no: "Nee"
    },
    explain: {
        title: "Uitleg {{subject}}",
        impersonate: "Imitatie",
        services: "Applicaties",
        serviceRequest: "Applicatieregistratie",
        apiKeys: "Organisatie-API-tokens",
        tokens: "Applicatietokens",
        serviceGroups: "Applicatiegroepen"
    },
    models: {
        users: {
            name_email: "Naam / e-mail",
            blank: "",
            icon: "",
            profile: "Je profiel",
            username: "Je gebruikersnaam: ",
            subProfile: "Aangemaakt op {{date}}",
            subOtherProfile: "Het profiel van {{name}} is aangemaakt op {{date}}",
            institute: "Instelling",
            instituteUnknown: "onbekend",
            instituteUnknownMeTooltip: "Je instelling heeft het identificerende attribuut 'schacHomeOrganization' niet vrijgegeven.",
            instituteUnknownTooltip: "De instelling van deze gebruiker heeft het identificerende attribuut 'schacHomeOrganization' niet vrijgegeven.",
            role: "Rol",
            title: "Platformbeheerders",
            platformAdmin: "Platformbeheerder",
            noEntities: "Er zijn nog geen leden...",
            searchPlaceHolder: "Zoek beheerders...",
            new: "Nieuw lid",
            me: "Jij",
            userTooltip: "Lid {{username}} sinds {{date}}",
            inviteTooltip: "Uitnodiging voor {{email}} verstuurd door {{name}} op {{date}}"
        },
        allUsers: {
            name_email: "Naam / e-mail",
            name: "Naam",
            email: "E-mail",
            entitlement: "Entitlement",
            eduperson_principal_name: "Eduperson principal name",
            institute: "Instelling",
            schac_home_organisation: "Instelling",
            found: "{{count}} {{plural}} gevonden",
            singleUser: "gebruiker",
            multipleUsers: "gebruikers",
            singleInvitation: "uitnodiging",
            multipleInvitations: "uitnodigingen",
            and: " en ",
            organisation: "Alle gebruikers in de samenwerkingen van deze organisatie",
            title: "Gebruikers",
            last_login_date: "Datum laatste activiteit",
            affiliation: "Affiliatie",
            username: "Username",
            uid: "Uid",
            noEntities: "Zoek eerst gebruikers...",
            noEntitiesInvitations: "Zoek eerst gebruikers of uitnodigingen...",
            searchPlaceHolder: "Zoek gebruikers...",
            me: "Jij",
            moreResults: "Er zijn nog meer zoekresultaten, verfijn je zoekterm.",
            noResults: "Geen gebruikers gevonden...",
            noResultsInvitations: "Geen gebruikers of uitnodigingen gevonden...",
            activity: "Gebruikersgeschiedenis",
            loading: "Geduld, alle historie wordt geladen....",
            showSsh: "Toon",
            ssh: {
                title: "Publieke SSH-sleutels van {{name}}"
            }
        },
        orgMembers: {
            title: "Organisatiebeheerders & -managers",
            noEntities: "Er zijn nog geen beheerders en managers in deze organisatie...",
            searchPlaceHolder: "Zoek leden...",
            status: "Status",
            inviteSend: "Uitgenodigd op {{date}}",
            expiredAt: "Uitnodiging verlopen",
            membershipNoExpiry: "Verloopt nooit",
            membershipExpiresAt: "Verloopt {{date}}",
            membershipExpiredAt: "Verlopen {{date}}",
            accepted: "Actief",
            expired: "Verlopen",
            expires: "Verloopt",
            suspended: "Geschorst",
            new: "Nodig beheerders uit",
            remove: "Verwijder",
            resend: "Opnieuw uitnodigen",
            mail: "E-mail",
            backToMembers: "Terug...",
            invitation: "Uitnodiging verstuurd door {{inviter}} naar {{email}} op {{date}}",
            removeTooltipDisabled: "Selecteer eerst gebruikers om te verwijderen",
            removeInvitationTooltip: "Verwijder uitnodiging",
            editManagerTooltip: "Bewerk manager",
            removeMemberTooltip: "Verwijder gebruiker",
            removeTooltip: "Verwijder alle geselecteerde leden",
            mailTooltipDisabled: "Selecteer eerst gebruikers om te e-mailen",
            mailTooltip: "E-mail alle geselecteerde leden",
            mailAdminTooltip: "E-mail beheerders",
            mailMemberTooltip: "E-mail lid",
            mailInvitationTooltip: "E-mail invitee",
            resendTooltipDisabled: "Selecteer eerst verlopen uitnodigingen om opnieuw te versturen",
            resendTooltip: "Verstuur alle verlopen geselecteerde opnieuw",
            resendInvitationTooltip: "Verstuur uitnodiging opnieuw",
        },
        orgInvitations: {
            title: "Openstaande organisatieuitnodigingen",
            noEntities: "Er zijn geen openstaande organisatieuitnodigingen...",
            searchPlaceHolder: "Zoek uitnodigingen...",
            backToMembers: "Terug naar openstaande uitnodigingen...",
            orgName: "Organisatie"
        },
        organisationsWithoutAdmin: {
            title: "Organisaties zonder beheerders",
            noEntities: "Er zijn geen organisaties zonder beheerders...",
            searchPlaceHolder: "Zoek organisaties...",
        },
        servicesWithoutAdmin: {
            title: "Applicaties zonder beheerders",
            noEntities: "Er zijn geen applicaties zonder beheerders...",
            searchPlaceHolder: "Zoek applicaties...",
        },
        organisations: {
            title: "Organisaties",
            noEntities: "Er zijn nog geen organisaties...",
            searchPlaceHolder: "Zoek organisaties...",
            new: "Voeg organisatie toe",
            name: "Naam",
            memberCount: "Beheerders",
            collaborationCount: "Samenwerkingen",
            schacHomeOrganisations: "Organisatiedomeinen",
            category: "Categorie",
            leave: "Verlaat organisatie"
        },
        services: {
            title: "Applicaties",
            noEntities: "Er zijn nog geen applicaties...",
            searchPlaceHolder: "Zoek applicaties...",
            new: "Voeg applicatie toe",
            request: "Applicatie registreren",
            name: "Naam",
            status: "Status",
            connectionRequestCount: "Koppelverzoeken",
            organisationCount: "Gebruikt in organisaties",
            collaborationCount: "Gebruikt in samenwerkingen",
            titleAvailableColl: "Beschikbaar voor deze samenwerking ({{count}})",
            titleRequestedColl: "Aangevraagd door gebruikers voor de samenwerking",
            titleAvailableOrg: "Beschikbaar voor deze organisatie",
            titleUsedColl: "Gebruikt door deze samenwerking",
            titleUsedOrg: "Verplicht door deze organisatie",
            awaitingApproval: "Koppeling in afwachting van goedkeuring",
            automaticConnectionAllowed: "Samenwerkingen mogen koppelen zonder toestemming",
            requiredByOrganisation: "Ingeschakeld door organisatie",
            memberServiceRequest: "Applicatiekoppelverzoek van beheerder",
            removeFromCO: "Ontkoppel",
            deleteConnectionRequest: "Verwijder koppelingsverzoek",
            resendConnectionRequest: "Vernieuw koppelingsverzoek",
            addToCO: "Koppel",
            removeFromOrg: "Ontkoppel van organisatie",
            addToOrg: "Voeg toe aan organisatie",
            mandatory: "Verplicht voor alle samenwerkingen",
            requestConnection: "Aanvragen",
            backToServices: "Terug naar applicaties",
            connectionRequest: "Vraag koppeling aan met {{name}}",
            confirmations: {
                add: "Weet je zeker dat je {{service}} beschikbaar wil maken voor alle leden van {{name}}?",
                remove: "Weet je zeker dat je {{service}} wil ontkoppelen van {{name}}?",
                check: "Ik ben bekend met de <a target=\"_blank\" href=\"{{aup}}\">AUP</a> van {{name}}",
                noPolicy: "Deze applicatie heeft geen privacy policy opgegeven"
            },
            statuses: {
                active: "Ingeschakeld door organisatie",
                pending: "Aangevraagd",
            },
            missingServices: "<a href=\"mailto:sram-support@surf.nl\">Stuur ons een verzoek</a> als je denkt dat een specifieke applicatie ontbreekt en moet worden vermeld."
        },
        collaborations: {
            title: "Samenwerkingen",
            titleForOrgAdmin: "Samenwerkingen gemanaged door je organisatie",
            searchPlaceHolder: "Zoek samenwerkingen...",
            new: "Voeg samenwerking toe",
            noEntities: "Geen samenwerkingen gevonden",
            newCollaborationRequest: "Nieuw verzoek voor samenwerking",
            name: "Naam",
            memberCount: "Leden",
            invitationsCount: "Uitnodigingen",
            allMembers: "Alle groepen",
            group: "Groep {{name}} ({{count}})",
            hideInvites: "Verberg uitnodigingen",
            noCollaborations: "Er zijn nog geen samenwerkingen in deze organisatie. Zullen we er een aanmaken?",
            noCollaborationsRequest: "Er zijn nog geen samenwerkingen in deze organisatie. Zullen we er een aanvragen?",
            noCollaborationsUser: "Je bent nog niet lid van een samenwerking. Zullen we er een aanmaken?",
            noCollaborationsRequestUser: "Je bent nog niet lid van een samenwerking. Zullen we er een aanvragen?",
            allLabels: "Alle labels",
        },
        serviceOrganisations: {
            searchPlaceHolder: "Zoek organisaties",
            title: "Organisaties",
            availableForAll: "Maak automatisch beschikbaar voor alle organisaties",
            notAvailableForAll: "Maak niet automatisch beschikbaar voor alle organisaties",
            disableAccessConfirmation: "Weet je zeker dat je voor deze applicatie de toegang wil verbieden?",
            disableAccessConsequences: "De volgende organisaties en samenwerkingen gebruiken deze applicatie en zullen hierdoor de applicatie niet meer kunnen gebruiken.",
            collaboration: "samenwerking",
            organisation: "organisatie",
            noEntities: "Er zijn geen organisaties die deze applicatie gebruiken",
            permissions: {
                eachOrganisation: "Configureer de toestemming per organisatie",
                allowAllRequests: "Koppelverzoeken handmatig goedkeuren van samenwerkingen van alle organisaties",
                allowAll: "Samenwerkingen van alle organisaties mogen zelfstandig koppelen"
            },
            options: {
                header: "Koppelingen met samenwerkingen",
                DISALLOW: "Niet",
                ON_REQUEST: "Op verzoek",
                ALWAYS: "Altijd"
            }
        },
        memberCollaborations: {
            searchPlaceHolder: "Zoek samenwerkingen...",
            title: "Samenwerkingen",
            new: "Vraag samenwerking aan",
            noEntities: "Er zijn geen samenwerkingen",
        },
        serviceCollaborations: {
            searchPlaceHolder: "Zoek samenwerkingen...",
            title: "Samenwerkingen die deze applicatie gebruiken",
            organisationName: "Organisatie",
            noEntities: "Er zijn geen samenwerkingen die deze applicatie gebruiken",
            organisationWarningTooltip: "Deze samenwerking heeft toegang omdat de organisatie van deze samenwerking alle samenwerkingen in deze organisatie toegang tot de applicatie heeft verleend.</br></br>Als je de toegang van deze samenwerking wil beëindigen, moet je de toegang van de organisatie op het tabblad Organisatie beëindigen.",
            origin: "Gekoppeld door",
            fromOrganisation: "Organisatie",
            fromCollaboration: "Samenwerking",
            disconnect: "Ontkoppelen",
            disconnectTooltip: "Ontkoppel alle geselecteerde samenwerkingen",
            disconnectOneTooltip: "Ontkoppel samenwerking",
            confirmation: {
                remove: "Weet je zeker dat je de geselecteerde samenwerkingen wil ontkoppelen? Je kan dit niet ongedaan maken.",
                removeOne: "Weet je zeker dat je de samenwerking {{name}} wil ontkoppelen? Je kan dit niet ongedaan maken.",
            },
            flash: {
                removed: "De samenwerking(en) zijn ontkoppeld"
            }
        },
        coAdmins: {
            searchPlaceHolder: "Zoek beheerders...",
            title: "Beheerders",
            new: "Nodig beheerders uit",
            noEntities: "Er zijn geen beheerder",
        },
        members: {
            searchPlaceHolder: "Zoek leden...",
            title: "Leden",
            new: "Nodig leden uit",
            noEntities: "Er zijn geen leden",
        },
        servicesAvailable: {
            title: "Beschikbare applicaties",
            searchPlaceHolder: "Zoek applicaties...",
            noEntities: "Er zijn geen applicaties beschikbaar om toe te voegen"
        },
        servicesUsed: {
            searchPlaceHolder: "Zoek applicaties...",
            noEntities: "Geen applicaties in gebruik"
        },
        servicesRequested: {
            searchPlaceHolder: "Zoek applicaties..."
        },
        groups: {
            backToGroups: "Terug naar all groepen",
            title: "Groepen",
            searchPlaceHolder: "Zoek groepen...",
            noEntities: "Geen groepen gevonden",
            new: "Voeg groep toe",
            edit: "Wijzigen",
            name: "Naam",
            urn: "Platform identifier",
            description: "Omschrijving",
            member: "Leden",
            memberCount: "Leden",
            service_group: "Voor applicatie",
            autoProvisioning: "Iedereen automatisch lid",
            on: "Aan",
            off: "Uit",
            deleteMemberConfirmation: "Weet je zeker dat je {{name}} wil verwijderen uit deze groep?",
            deleteMembersConfirmation: "Weet je zeker dat je de geselecteerde leden wil verwijderen uit deze groep?",
            deleteGroupConfirmation: "Weet je zeker dat je de group {{name}} wilt verwijderen?",
            deleteGroupsConfirmation: "Weet je zeker dat je de geselecteerde groepen wilt verwijderen?",
            removeGroupTooltip: "Verwijder groep",
            removeTooltip: "Verwijder alle geselecteerde groepen",
        },
        groupMembers: {
            title: "Leden",
            searchPlaceHolder: "Zoek leden",
            noEntities: "Geen leden",
            addMembersPlaceholder: "Zoek en selecteer om leden toe te voegen"
        },
        collaboration: {
            memberHeader: "{{nbrMember}} leden – {{nbrGroups}} groepen",
            memberInformation: "Neem contact op met een beheerder",
            noAdminsHeader: "(Nog) geen beheerders",
            adminsHeader: "Georganiseerd door <a style=\"display:inline;\" href=\"mailto:{{bcc}}{{mails}}\"><strong>{{name}}</strong></a>",
            twoAdminsHeader: "Georganiseerd door <a style=\"display:inline;\" href=\"mailto:{{bcc}}{{mails}}\"><strong>{{name}}</strong> en <strong>{{nbr}} ander</strong></a>",
            multipleAdminsHeader: "Georganiseerd door <a style=\"display:inline;\" href=\"mailto:{{bcc}}{{mails}}\"><strong>{{name}}</strong> en <strong>{{nbr}} anderen</strong></a>",
            about: "Over onze samenwerking",
            services: "We werken samen in {{nbr}} applicaties",
            joinRequestServices: "{{name}} werkt samen in {{nbr}} applicaties",
            noServicesYet: "Waar we samenwerken",
            noServices: "Er zijn geen applicaties gekoppeld aan je samenwerking. ",
            connectFirstService: "Wil je de eerste koppelen?",
            noServicesJoinRequest: "Applicaties zijn alleen zichtbaar voor leden",
            servicesStart: "Gebruik de knop om de applicatie te starten, of lees eerst de instructies.",
            instructions: "Instructies",
            servicesHoover: "Bezoek deze applicatie op {{uri}}",
            members: "Leden ({{nbr}})",
            showMemberDetails: "Toon alle details",
            admin: "beheerder",
            more: "Toon {{nbr}} meer",
            less: "Toon minder",
            viewAsMember: "Bekijk als lid",
            viewAsAdmin: "Bekijk als beheerder",
            discloseNoMemberInformation: "Deze samenwerking deelt geen ledeninformatie",
            discloseNoMemberInformationJoinRequest: "Alleen zichtbaar voor leden",
            newCollaborationRequest: "Aanvragen samenwerking",
            leave: "Lidmaatschap opzeggen",
            labels: "Labels",
        },
        apiKeys: {
            title: "Organisatie-API-tokens",
            new: "Voeg organisatie-API-token toe",
            searchPlaceHolder: "Zoek organisatie-API-tokens...",
            noEntities: "Geen organisatie-API-tokens",
            backToApiKeys: "Terug naar alle organisatie-API-tokens"
        },
        collaboration_requests: {
            title: "Aanvragen voor samenwerkingen",
            requester: "Aanvrager",
            searchPlaceHolder: "Zoek samenwerkingsaanvragen...",
            noEntities: "Geen aanvragen voor samenwerkingen",
        },
        service_requests: {
            title: "Applicatieregistratie",
            requester: "Aanvrager",
            protocol: "Protocol",
            searchPlaceHolder: "Zoek applicatieregistratie...",
            noEntities: "Geen aanvragen voor registratie",
        },
        member_collaboration_requests: {
            title: "Mijn samenwerkingensaanvragen",
            searchPlaceHolder: "Zoek samenwerkingsaanvragen...",
            noEntities: "Geen samenwerkingenaanvragen",
        },
        my_requests: {
            title: "Mijn aanvragen",
            searchPlaceHolder: "Zoek naar aanvragen...",
            noEntities: "Je hebt geen aanvragen gedaan",
        },
        system_collaboration_requests: {
            title: "Verlopen aanvragen voor samenwerkingen",
            searchPlaceHolder: "Zoek samenwerkingsaanvragen...",
            noEntities: "Geen verlopen samenwerkingenaanvragen",
        },
        deleted_collaboration_requests: {
            title: "Verwijderde samenwerkingenaanvragen",
            searchPlaceHolder: "Verzoeken zoeken...",
            noEntities: "Geen verlopen geweigerde / goedgekeurde samenwerkingenaanvragen",
        },
        invitation: {
            welcome: "Welkom in SURF Research Access Management (SRAM). Hier wordt de toegang tot onderzoeksapplicaties geregeld voor onderzoekssamenwerkingen.",
            invited: "Je bent door <a href=\"mailto:{{email}}\">{{inviter}}</a> uitgenodigd lid te worden van {{type}} '{{collaboration}}'",
            followingSteps: "SURF Research Access Management geeft leden van onderzoekssamenwerkingen snel en veilig toegang tot onderzoeksapplicaties.</br> Inloggen kan met je instellingsaccount of eduID.",
            steps: {
                progress: "{{now}} van {{total}}",
                login: "Inloggen",
                next: "Hierna: {{step}}",
                invite: "Doe mee met de samenwerking",
                inviteOrg: "Doe mee met de organisatie",
                inviteService: "Beheer je applicatie",
                collaborate: "Samenwerken!",
            },
            login: "Log in om je te identificeren",
            loginWithSub: "Log in om de uitnodiging te accepteren",
            loginTip: "Je kunt je instellingsaccount gebruiken om in te loggen, of een gastaccount gebruiken zoals eduID",
            accept: "Ik accepteer de voorwaarden",
            noAccept: "Nee, ik ga niet akkoord",
            acceptInvitation: "Ja, ik accepteer de uitnodiging",
            declineInvitation: "Nee, ik wijs de uitnodiging af",
            confirmations: {
                cancelInvitation: "Weet je zeker dat je de voorwaarden wil weigeren?",
                declineInvitation: "Weet je zeker dat je de uitnodiging wil weigeren?",
            },
            disclaimer: "Als je doorgaat, zal SURF gegevens over jou doorsturen naar de onderzoekssamenwerking. Elke gegevensverwerking door de samenwerking, zoals het overdragen van gegevens aan applicaties zodat je daar toegang toe hebt, is een verantwoordelijkheid van de samenwerking.",
            disclaimerAup: "De samenwerking heeft SURF gemeld dat op <a href=\"{{aup}}\" target=\"_blank\">deze URL</a> nog relevante informatie voor je staat.",
            disclaimerQuestions: "Heb je vragen over de verwerking van je gegevens door de samenwerking? Neem contact op met de persoon die je heeft uitgenodigd."
        },
        joinRequests: {
            backToJoinRequests: "Terug naar alle verzoeken",
            title: "Verzoek om lid te worden",
            searchPlaceHolder: "Zoek verzoeken...",
            noEntities: "Geen verzoeken gevonden",
            edit: "Accepteer / keur af",
            details: "Verzoek gemaakt door {{name}} op {{date}}"
        },
        memberJoinRequests: {
            title: "Mijn verzoeken voor samenwerkingen",
            searchPlaceHolder: "Zoek verzoeken...",
            noEntities: "Geen verzoeken gevonden",
            collaborationName: "Naam samenwerking",
            requested: "Verzocht op",
            organisationName: "Instelling",
        },
        systemJoinRequests: {
            title: "Verlopen open verzoeken voor samenwerkingen",
            searchPlaceHolder: "Zoek verzoeken...",
            noEntities: "Geen verlopen verzoeken gevonden",
        },
        deletedJoinRequests: {
            title: "Verwijderde verzoeken lid te worden van een samenwerking",
            searchPlaceHolder: "Zoek naar verzoeken...",
            noEntities: "Geen verlopen goedgekeurde / afgekeurde verzoeken gevonden",
        },
        serviceConnectionRequests: {
            backToServiceConnectionRequests: "Terug naar alle koppelverzoeken",
            title: "Koppelverzoeken",
            searchPlaceHolder: "Zoek koppelverzoeken...",
            noEntities: "Geen koppelverzoeken gevonden",
            edit: "Goed- / afkeuren",
            details: "Applicatiekoppelverzoek gemaakt door {{name}} op {{date}} voor samenwerking {{collaborationName}}",
            detailsWithService: "Applicatiekoppelverzoek gemaakt door {{name}} op {{date}} voor samenwerking {{collaborationName}} en applicatie {{serviceName}}",
            requester: "Aanvrager",
            name: "Samenwerking",
            serviceName: "Applicatie"
        },
        serviceGroups: {
            backToGroups: "Terug naar alle applicatiegroepen",
            autoProvisionMembersTooltip: "Vink aan om automatisch alle bestaande leden en nieuwe leden toe te voegen aan deze groep als deze wordt aangemaakt",
            title: "Applicatiegroepen",
            searchPlaceHolder: "Zoek applicatiegroepen...",
            noEntities: "Geen applicatiegroepen gevonden",
            new: "Nieuwe applicatiegroep",
            edit: "Wijzigen",
            name: "Naam",
            description: "Omschrijving",
            autoProvisioning: "Iedereen automatisch lid",
            on: "Aan",
            off: "Uit"
        },
        serviceAdmins: {
            searchPlaceHolder: "Zoek naar applicatiebeheerders..",
            noEntities: "Er zijn geen applicatiebeheerders of applicatiemanagers",
            new: "Nodig beheerders uit",
            title: "Applicatiebeheerders & applicatiemanagers"
        },
        userTokens: {
            backToUserTokens: "Terug naar alle applicatietokens",
            title: "Applicatietokens",
            searchPlaceHolder: "Zoek naar applicatietokens...",
            noEntities: "Geen applicatietokens gevonden",
            new: "Maak applicatietoken aan",
            edit: "Wijzigen",
            name: "Naam",
            namePlaceholder: "De naam van dit applicatietoken",
            description: "Omschrijving",
            descriptionPlaceholder: "De omschrijving van dit applicatietoken",
            deleteConfirmation: "Weet je zeker dat je {{name}} wil verwijderen?",
            expiryDate: "Vervaldatum",
            expiredInfo: "Dit applicatietoken is verlopen. Je kan het hieronder heractiveren",
            reactivate: "Heractiveren",
            createdAt: "Aangemaakt op",
            expiryDateTooltip: "Na deze datum kan je dit applicatietoken niet meer gebruiken",
            service: "Applicatie",
            lastUsedDate: "Laatst gebruikt",
            serviceTooltip: "De applicatie gekoppeld aan dit token. Je hebt alleen toegang tot de API van deze applicatie met dit token",
            hashedToken: "Applicatietoken",
            hashedTokenTooltip: "De applicatietoken om te gebruiken in de authorization header",
            tokenDisclaimer: "Kopieer de applicatietoken en sla het veilig op. Je kunt dit token maar één keer zien. Nadat je op <i>Opslaan</i> hebt gedrukt, kun je het niet meer zien.",
            required: "Dit {{attribute}} is verplicht voor een applicatietoken",
            flash: {
                created: "Applicatietoken {{name}} aangemaakt",
                updated: "Applicatietoken {{name}} bijgewerkt",
                reactivated: "Applicatietoken {{name}} geheractiveerd",
            }
        },
        scimServices: {
            searchPlaceHolder: "Search for SCIM applications..",
            noEntities: "There are no applications configured with the mock SCIM endpoint",
        }
    },
    user: {
        titleUpdate: "Werk de SSH-sleutels in je gebruikersprofiel bij",
        ssh_key: "Publieke SSH-sleutel",
        ssh_keys_zero_state: "Er zijn geen SSH-sleutels geassociëerd bij je profiel",
        ssh_keyPlaceholder: "Je publieke SSH-sleutel om in te loggen op de applicatie",
        ssh_keyTooltip: "Je publieke SSH-sleutel zal worden uitgewisseld met de applicaties waar je toegang toe hebt. Je kunt je publieke SSH-sleutel ook uploaden.",
        sshKeyError: "Deze SSH cipher wordt niet herkend. In de <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"https://edu.nl/87btm\">documentatie</a> staan de ondersteunde formaten.",
        sshConvertInfo: "Je publieke SSH-sleutel wordt geconverteerd naar het bestandsformaat <code>OpenSSH-key</code> bij het wijzigen van het profiel",
        update: "Wijzigen",
        download: "Download mijn gegevens",
        flash: {
            updated: "Je profiel is bijgewerkt"
        },
        providedBy: "Geleverd door <strong>{{institution}}</strong>",
        generatedBy: "Gegenereerd door <strong>SRAM</strong>",
        delete: "Verwijder mijn profiel",
        deleteConfirmation: "Weet je zeker dat je je profiel voorgoed wil verwijderen?",
        deleteConfirmationWarning: "Al je lidmaatschappen worden verwijderd. Dit kan niet worden teruggedraaid.",
        deleteConfirmationCheck: "Als je toch wil doorgaan, typ dan ter bevestiging je volledige naam in.",
        deleteOtherConfirmation: "Weet je zeker dat je je gebruiker {{name}} wilt verwijderen?",
        unsuspend: "Schorsing doorhalen",
        deleteOther: "Verwijder",
        unsuspendOtherConfirmation: "Weet je zeker dat je de schorsing van gebruiker {{name}} wilt doorhalen?",
        reset2fa: "Reset TOTP",
        reset2faConfirmation: "Weet je zeker dat je de TOTP van gebruiker {{name}} wilt resetten?",
    },
    impersonate: {
        title: "Wie wil je zijn?",
        organisation: "Organisatie",
        organisationPlaceholder: "Voer de naam van een organisatie in om de lijst met zoekresultaten te beperken",
        organisationAdminsOnly: "Toon alleen de beheerders van organisaties",
        collaboration: "Samenwerking",
        collaborationPlaceholder: "Voer de naam van een samenwerking in om de lijst met zoekresultaten te beperken",
        collaborationAdminsOnly: "Toon alleen de beheerders van samenwerkingen",
        user: "Gebruiker",
        userSearchPlaceHolder: "Voer de naam van een gebruiker in om te imiteren",
        userRequired: "Kies een gebruiker om te imiteren.",
        currentImpersonation: "Je imiteert",
        noImpersonation: "Je bent wie je bent - je imiteert niemand.",
        currentImpersonationValue: "Je ziet SURF Research Access Management nu als {{currentUser}}, maar je bent natuurlijk {{impersonator}}.",
        startImpersonation: "Imiteer",
        clearImpersonation: "Stop met imiteren",
        flash: {
            startedImpersonation: "Gelukt: je bent nu - tijdelijk - {{name}}.",
            clearedImpersonation: "Gelukt: imiteren gestopt. Je bent jezelf weer."
        },
        exit: "Stop met imiteren",
        impersonator: "Je bent <strong>{{name}}</strong> aan het imiteren | <strong>{{role}}</strong>",
        impersonatorTooltip: "Je bent echt <em>{{impersonator}}</em>, maar je imiteert <em>{{currentUser}}</em>. Op de <strong>Imitatie</strong>pagina kun je je identiteit veranderen of jezelf weer worden."
    },
    registration: {
        joinRequest: "Vraag lidmaatschap voor {{name}}",
        explanation: "Schrijf je motivatie om lid te worden van samenwerking <strong>{{name}}</strong>.",
        title: "Verzoek tot lidmaatschap van {{name}}",
        alreadyMember: "Je bent reeds lid van {{name}}",
        motivation: "Je reden om lid te worden van {{name}}?",
        motivationPlaceholder: "Omschrijf de reden om lid te worden zodat een beheerder je verzoek kan honoreren.",
        policyConfirmation: "{{collaboration}} heeft een AUP waar je <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"{{aup}}\"'>hier</a> meer over kan lezen.",
        feedback: {
            info: "Je verzoek is verzonden naar de beheerders van <strong>{{name}}</strong> die je aanvraag zullen beoordelen. De beslissing wordt je per e-mail meegedeeld.",
        },
    },
    profile: {
        name: "Naam",
        email: "E-mail",
        uid: "UID",
        affiliation: "Affiliations",
        username: "Gebruikersnaam",
        scoped_affiliation: "Scoped affiliations",
        entitlement: "Entitlements",
        nick_name: "Roepnaam",
        schac_home_organisation: "Organisatiedomein",
        edu_members: "EDU lidmaatschap",
        eduperson_principal_name: "EPPN",
        created_at: "Profiel gemaakt op",
        superUser: "Super User",
        yourRole: "Je rol",
        role: "Rol",
        member: "Lid",
        manager: "Manager",
        admin: "Beheerder",
        organisation: "Organisatie",
        organisations: "Organisaties",
        sbs: "Applicatie",
        collaborations: "Samenwerkingen",
        network: "IP-ranges",
        addSSHKey: "Upload een SSH-sleutel",
        addSSHKeyManually: "Voeg handmatig een SSH-sleutel toe",
        networkTooltip: "Configureer je IP-bereiken voor deze applicatie. Je kunt IPv4 en IPv6 network ranges toevoegen. " +
            "Bijvoorbeeld: <ul>" +
            "<li>Enkelvoudig IPv4-adres 198.51.100.12 waar een /32 is aangenomen</li>" +
            "<li>IPv4-bereik 198.51.100.0/24. Maximaal toegestane omvang subnet voor IPv4 is een /24</li>" +
            "<li>IPv6-bereik 2001:db8:f00f:bab::/64. Maximaal toegestane omvang subnet voor IPv6 is een /64</li>" +
            "</ul> ",
        networkExplanation: "Informeer de applicatieaanbieders vanaf welke IP-adressen je hun applicatie gebruikt. De applicatieaanbieders kunnen je IP-adressen toegang geven als ze dit ondersteunen.",
        deleteHeader: "Verwijder je profiel",
        deleteDisclaimer: "Als je je profiel verwijdert, worden ook al je lidmaatschappen en beheerrollen verwijderd. Dit kan niet ongedaan worden gemaakt."
    },
    collaborations: {
        dashboard: "Dashboard voor je samenwerkingen",
        dashboardAdmin: "Beheer-dashboard voor mijn samenwerkingen",
        dashboardAdminTooltip: "Dit is een overzicht van alle samenwerkingen waar je een van de beheerders van bent.",
        title: "Mijn samenwerkingen",
        noCollaborations: "Je bent geen lid van een samenwerking",
        requests: "Lidmaatschapsverzoeken",
        group: "Groep",
        groups: "Groepen",
        moreInformation: "Meer informatie",
        website: "Website",
        invitations: "Uitnodigingen",
        services: "Applicaties",
        add: "Nieuwe samenwerking",
        searchPlaceHolder: "Zoek op alle samenwerkingen"
    },
    accessTypes: {
        open: "Open",
        closed: "Besloten",
        on_acceptance: "Na goedkeuring"
    },
    collaboration: {
        about: "Over de samenwerking",
        invitations: "Beheerders uitnodigen",
        title: "Nieuwe samenwerking maken",
        requestTitle: "Nieuwe samenwerking aanvragen",
        requestTitleCreationAllowed: "Nieuwe samenwerking aanvragen - organisatie {{name}} is geconfigureerd om automatisch aanvragen voor een samenwerking goed te keuren",
        actions: "",
        name: "Naam",
        logo: "Logo samenwerking",
        useOrganisationLogo: "Gebruik het logo van de organisatie",
        generateLogo: "Genereer logo",
        generateLogoTooltip: "Je moet eerst de naam van de samenwerking invullen om een logo te kunnen genereren",
        namePlaceHolder: "De unieke naam van de samenwerking",
        shortName: "Korte naam",
        shortNamePlaceHolder: "Korte naam van de samenwerking",
        shortNameTooltip: "Ken korte namen toe aan de samenwerkingen zodat die namen bruikbaar zijn in de via ldap te koppelen applicaties (zoals Linux groepsnamen).<br/><br/>" +
            "Alleen getallen, alfanumerieke karakers en de lage streep zijn toegstaan.",
        globalUrn: "Platform identifier",
        globalUrnTooltip: "Binnen het platform unieke en onaanpasbare identifier, gebaseerd op de korte naam van de organisatie en de samenwerking.",
        identifier: "Identifier",
        identifierTooltip: "Gegenereerde, unieke en niet aanpasbare identifier van een samenwerking die wordt gebruikt als identifier voor externe systemen",
        joinRequestUrlTooltip: "URL voor niet-leden om zich aan te melden voor deze samenwerking. De URL kunt je bijvoorbeeld e-mailen of publiceren op een website.",
        joinRequestUrlDisabled: "Lidmaatschapsverzoek is uitgeschakeld voor deze samenwerking",
        allowJoinRequests: "Lidmaatschapsverzoek toestaan",
        allowJoinRequestsTooltip: "Als dit is aangevinkt, kunnen gebruikers een verzoek doen om lid te worden.",
        joinRequests: "Link voor lidmaatschapsverzoek",
        joinRequestsHeader: "Lidmaatschapsverzoek",
        noJoinRequests: "niet toegestaan",
        memberList: "Lidmaatschappen",
        hidden: "Verborgen",
        visible: "Zichtbaar",
        disabled: "Uitgeschakeld",
        enabled: "Kopieer",
        aup: "Acceptable use policy",
        noAup: "Geen acceptable use policy opgegeven",
        expiryDate: "Einddatum",
        expiryDateWarning: {
            one: "Verloopt over 1 dag!",
            other: "Verloopt over {{nbr}} dagen!"
        },
        expiryDateExpired: "Verlopen!",
        lastActivityDate: "Laatste activiteit",
        lastActivitySuspended: "Geschorst",
        expiryDateTooltip: "De einddatum van de samenwerking. Na deze datum wordt de samenwerking geschorst en kunnen gebruikers van deze samenwerking geen gebruik meer maken van de applicaties",
        discloseMembers: "Ledenlijst zichtbaar voor alle leden",
        discloseNoMembers: "Ledenlijst niet inzichtelijk voor leden",
        discloseMemberInformation: "Informatie van leden tonen",
        discloseMemberInformationTooltip: "Indien aangevinkt wordt informatie van leden aan andere leden getoond",
        discloseEmailInformation: "E-mailadressen tonen",
        discloseEmailInformationTooltip: "Indien aangevinkt zijn e-mail-adressen van leden voor andere leden zichtbaar",
        description: "Doel van de samenwerking",
        descriptionPlaceholder: "Beschrijf duidelijk wat het doel van de samenwerking is, waaruit blijkt dat de verwerking van persoonsgegevens gerechtvaardigd is",
        websiteUrl: "Website URL",
        websiteUrlPlaceholder: "De URL van de website is zichtbaar voor leden",
        support_emailPlaceholder: "Het e-mailadres of de website van de helpdesk voor deze samenwerking",
        supportShort: "Support",
        currentUserAdmin: "Maak mijzelf beheerder van deze samenwerking",
        currentUserAdminTooltip: "Indien aangevinkt wordt je toegevoegd als beheerder van de samenwerking",
        message: "Optioneel bericht",
        messagePlaceholder: "Persoonlijk bericht aan de beheerders",
        messageTooltip: "De tekst nemen we op in de  e-mail aan de beheerders.",
        motivation: "Motivatie",
        motivationPlaceholder: "Motivatie voor de nieuwe samenwerking",
        motivationTooltip: "Motiveer waarom je een nieuwe samenwerking aanvraagt.",
        organisation_name: "Verantwoordelijke organisatie",
        organisationPlaceholder: "Kies de organisatie voor deze samenwerking",
        organisationTooltip: "Iedere samenwerking hoort bij precies één organisatie",
        tags: "Labels",
        tagsPlaceholder: "Zoek of maak labels",
        tagsTooltip: "Een samenwerking kan meerdere labels hebben. Niet alle tekens zijn toegestaan, omdat labels kunnen worden gebruikt als Linux-groepsnamen.",
        accepted_user_policy: "Acceptable use policy",
        acceptedUserPolicyPlaceholder: "De URL van de samenwerkingsspecifieke acceptable use policy",
        role: "Rol",
        newTitle: "Voeg nieuwe samenwerking toe",
        subTitle: "Beschrijf de samenwerking. Je wordt beheerder van de nieuwe samenwerking.",
        alreadyExists: "Er bestaat al een samenwerking met {{attribute}} {{value}}.",
        required: "{{attribute}} moet worden ingevuld voor een samenwerking.",
        administrators: "Beheerders",
        administratorsPlaceholder: "Nodig beheerders uit per e-mail",
        administratorsTooltip: "Beheerders van een samenwerking kunnen de samenwerking aanpassen en leden uitnodigen. Voer e-mailadressen in, gescheiden door een komma, spatie of punt-komma, of druk enter na elk adres.",
        members: "Leden",
        selectRole: "Selecteer een rol.",
        manager: "Manager",
        admin: "Beheerder",
        member: "Lid",
        status: {
            name: "Status",
            active: "Actief",
            activeWithExpiryDate: "Verloopt op {{expiryDate}}",
            suspended: "Geschorst",
            expired: "Verlopen",
            activeTooltip: "Deze samenwerking is actief.",
            suspendedTooltip: "Deze samenwerking is geschorst wegens inactiviteit. De laatste activiteit was op {{lastActivityDate}}. ",
            revertSuspension: " Je kan de schorsing ongedaan maken met de knop hieronder.",
            almostSuspended: "Deze samenwerking zal over {{days}} dagen worden geschorst vanwege inactiviteit.",
            revertAlmostSuspended: " Je kan de schorsing voorkomen met de knop hieronder.",
            expiredTooltip: "Deze samenwerking is verlopen op {{expiryDate}}. ",
            activate: "Heractiveer",
            askForExtension: "Vraag verlenging lidmaatschap",
            askForReactivation: "Vraag activatie lidmaatschap ",
            askForExtensionSubject: "Vraag verlenging lidmaatschap voor gebruiker {{email}}",
            askForReactivationSubject: "Vraag activatie lidmaatschap voor gebruiker {{email}}",
            activeWithExpiryDateTooltip: "Deze samenwerking verloopt op {{expiryDate}}. ",
            activeWithExpiryDateAction: "Collaboration settings",
        },
        flash: {
            created: "Samenwerking {{name}} is aangemaakt.",
            requested: "Samenwerking {{name}} is aangevraagd."
        }
    },
    collaborationDetail: {
        title: "Details samenwerking {{name}}",
        backToCollaborations: "Terug naar mijn samenwerkingen",
        backToHome: "Terug naar mijn dashboard",
        backToCollaborationDetail: "Terug naar mijn samenwerking {{name}}",
        update: "Opslaan",
        delete: "Verwijderen",
        deleteConfirmation: "Weet je zeker dat je deze samenwerking wil verwijderen? Dit kan niet worden teruggedraaid.",
        deleteMemberConfirmation: "Weet je zeker dat je {{name}} als lid voor deze samenwerking wil verwijderen?",
        deleteInvitationConfirmation: "Weet je zeker dat je de uitnodiging voor {{name}} wil verwijderen?",
        deleteEntitiesConfirmation: "Weet je zeker dat je de gemarkeerde leden en uitnodigingen wil verwijderen?",
        deleteYourselfMemberConfirmation: "Weet je zeker dat je deze samenwerking wil verlaten? Je kan dit niet terugdraaien.",
        noBatchDeleteAllowed: "Het is niet mogelijk om de samenwerking te verlaten in een batchverwijdering. Selecteer alleen jezelf om deze samenwerking te verlaten of selecteer alleen andere leden om anderen uit de groep te verwijderen.",
        downgradeYourselfMemberConfirmation: "Weet je zeker dat je geen beheerder meer wil zijn? Je kan dit niet terugdraaien.",
        activateMemberConfirmation: "Weet je zeker dat je lid {{name}} wil her-activeren? We zullen een e-mail met een activeringslink naar de gebruiker sturen.",
        lastAdminWarning: "Je laat deze samenwerking achter zonder beheerders. Hierna kan het beheer van de samenwerking alleen worden uitgevoerd door de beheerders van organisatie {{name}}",
        lastAdminWarningUser: "Je bent de laatste beheerder in deze samenwerking. Na je vertrek kan het beheer van de samenwerking alleen worden uitgevoerd door de beheerders van organisatie {{name}}",
        activateMemberTooltip: "Klik om de gedeactiveerde gebruiker weer te activeren. Gedeactiveerde gebruikers kunnen niet inloggen",
        expiryDateChange: "Weet je zeker dat je de einddatum voor {{name}} wil veranderen naar {{date}}?",
        expiryDateReset: "Weet je zeker dat je de einddatum voor {{name}} wil verwijderen?",
        flash: {
            updated: "Samenwerking {{name}} is bijgewerkt.",
            deleted: "Samenwerking {{name}} is verwijderd.",
            memberDeleted: "{{name}} is geen lid meer van deze samenwerking.",
            invitationDeleted: "Uitnodiging voor {{name}} is verwijderd",
            entitiesDeleted: "Lidmaatschap / uitnodigingen zijn verwijderd",
            memberUpdated: "De rol of lidmaatschap van {{name}} is bijgewerkt naar {{role}}.",
            meAdded: "Je bent toegevoegd als lid van {{name}}",
            memberActivated: "Lid {{name}} heeft een e-mail met een activeringslink ontvangen",
            memberExpiryDateUpdated: "Lidmaatschap van {{name}} heeft als nieuwe einddatum {{expiryDate}}"
        },
        infoBlocks: "Dashboard samenwerking {{name}}",
        searchPlaceHolder: "Zoek leden",
        noMembers: "Samenwerking {{name}} heeft geen leden.",
        members: "Leden van {{name}}",
        member: {
            user__name: "Naam",
            user__email: "E-mail",
            user__uid: "UID",
            user__suspended: "Geschorst",
            role: "Rol",
            created_at: "Sinds",
            actions: ""
        },
        invite: "Uitnodigen",
        addMe: "Voeg mij toe"
    },
    organisations: {
        title: "Mijn organisaties ({{nbr}})",
        noOrganisations: "Je bent nog geen lid van een organisatie",
        members: "Leden",
        collaborations: "Samenwerkingen",
        invitations: "Uitnodigingen",
        collaborationRequests: "Samenwerkingsaanvragen",
        add: "Nieuwe organisatie maken",
        searchPlaceHolder: "Doorzoek alle organisaties",
        deleteConfirmation: "Weet je zeker dat je organisatie {{name}} wil verwijderen? Dit kan niet teruggedraaid worden."
    },
    services: {
        title: "Applicaties",
        add: "Nieuwe applicatie",
        searchPlaceHolder: "Doorzoek alle applicaties",
        toc: {
            connections: "Koppelingen",
            available: "Beschikbare applicaties",
        }
    },
    service: {
        about: "Over de applicatie",
        aboutShort: "Over",
        logo: "Applicatielogo",
        accessAllowed: "Toegang toegestaan",
        compliancy: "Applicatie compliancy",
        compliancyShort: "Compliancy",
        compliancySeparator: "en",
        none: "Geen",
        contact: "Applicatiecontactpersoon",
        titleNew: "Applicatie toevoegen",
        titleRequest: "Applicatie registreren",
        titleReadOnly: "Applicatie {{name}}",
        backToServices: "Terug naar applicaties",
        name: "Naam",
        namePlaceHolder: "De unieke naam van de applicatie",
        entity_id: "Entity ID",
        entity_idPlaceHolder: "De unieke entity ID van de applicatie",
        entity_idTooltip: "De Entity ID is bij SAML (Entity ID) en OIDC (client_id) het unieke kenmerk van je applicatie voor de identity proxy. Het wordt ook gebruikt bij provisioning via LDAP.",
        abbreviation: "Korte naam",
        abbreviationPlaceHolder: "De korte naam van de applicatie",
        abbreviationTooltip: "De korte naam van een applicatie wordt gebruikt als prefix voor groepen aangemaakt door de applicatiegroepen van deze applicatie.<br/><br/>" +
            "Alleen getallen, alfanumerieke karakers en de lage streep zijn toegstaan.",
        service_request: "Link voor applicatieregistratie",
        service_requestTooltip: "De URL om een koppeling met de applicatie aan te vragen door een lid van samenwerking",
        service_requestError: "Vereist een valide URI en samenwerkingen moeten kunnen koppelen zonder toestemming",
        description: "Omschrijving",
        descriptionPlaceholder: "De omschrijving van de applicatie",
        uri: "Inlog-URL",
        uriPlaceholder: "De URL waar gebruikers inloggen",
        uriTooltip: "URL waarop gebruikers zich dienen aan te melden bij de applicatie. Wordt duidelijk getoond aan leden van samenwerkingen.<br/><br/>" +
            "Deze variabelen worden vervangen door de corresponderende waarden: <em>{CO_short_name}</em> en <em>{username}</em>.",
        infoUri: "Website",
        infoUriPlaceholder: "De URL met informatie over deze applicatie",
        infoUriTooltip: "URL van een website met informatie over deze applicatie",
        privacy_policy: "Privacyverklaring",
        privacy_policyPlaceholder: "De URL van het privacyverklaring van deze applicatie",
        privacy_policyTooltip: "De wet vereist een privacyverklaring voor alle websites en apps die persoonlijke informatie van gebruikers verzamelen of gebruiken.",
        accepted_user_policy: "Acceptable use policy URL",
        accepted_user_policyPlaceholder: "De acceptable use policy (AUP) van de applicatie",
        accepted_user_policyTooltip: "Een acceptable use policy (AUP) is een document waarin staat wat een gebruiker wel en niet mag/hoort te doen en waarmee de gebruiker akkoord moet gaan om toegang te krijgen tot een applicatie of systeem.",
        network: "ACL IP-ranges",
        networkTooltip: "De IP-reeksen die deze applicatie gebruikt om de LDAP-server van het platform te bereiken. Zowel IPv4- als IPv6-bereiken zijn toegestaan. " +
            "Bijvoorbeeld: <ul>" +
            "<li>Enkelvoudig IPv4-adres 198.51.100.12 waarbij een /32 wordt aangenomen</li>" +
            "<li>IPv4-bereik 198.51.100.0/24. Maximaal toegestane omvang subnet voor IPv4 is een /24</li>" +
            "<li>IPv6-bereik 2001:db8:f00f:bab::/64. Maximaal toegestane omvang subnet voor IPv6 is een /64</li>" +
            "</ul> ",
        networkPlaceholder: "IPv4-of IPv6-adres met optioneel subnet",
        networkError: "Die {{prefix}} subnetomvang is niet toegestaan. Maximaal toegestane prefix voor IPv{{version}} is {{max}}",
        networkSyntaxError: "Dit is geen geldig IPv4- of IPv6-adres",
        networkReservedError: "Dit is een gereserveerd IPv{{version}}-adres",
        networkNotGlobal: "Alleen globale unicast-adressen kunnen worden ingevoerd",
        networkInfo: "Laagste IP: {{lower}}, hoogste IP: {{higher}}, # adressen: {{num_addresses}}, versie: IPv{{version}}",
        automaticConnectionAllowed: "Samenwerkingen mogen koppelen zonder jouw toestemming",
        automaticConnectionAllowedTooltip: "Indien ingeschakeld mag een samenwerking deze applicatie koppelen zonder toestemming van de applicatieeigenaar (jou). Er wordt dan geen koppelverzoek ter goedkeuring voorgelegd.",
        automaticConnectionAllowedOrganisations: "Vertrouwde / je eigen organisaties",
        automaticConnectionAllowedOrganisationsTooltip: "Als samenwerkingen geen koppeling mogen maken zonder toestemming, kan je organisaties selecteren die je vertrouwt of waarvan u eigenaar bent, en de samenwerkingen van die organisaties kunnen alsnog koppelen zonder toestemming.",
        automaticConnectionAllowedOrganisationsPlaceHolder: "Selecteer organisaties...",
        accessAllowedForAll: "Beschikbaar voor alle organisaties",
        accessAllowedForAllTooltip: "Is deze applicatie voor alle huidige en toekomstige organisaties standaard beschikbaar?",
        accessAllowedForAllInfo: "Samenwerkingen van alle organisaties kunnen koppelen met deze applicatie.",
        nonMemberUsersAccessAllowed: "Toegankelijk zonder lidmaatschap van een samenwerking.",
        nonMemberUsersAccessAllowedTooltip: "Alle gebruikers hebben toegang tot deze applicatie, zonder lidmaatschap een samenwerking.",
        connectCollaborationsInfo: "Samenwerkingen van sommige organisaties kunnen koppelen, en koppelinstellingen verschillen per instelling.",
        noOneAllowed: "Niemand mag een samenwerking koppelen met deze applicatie.",
        viewSettings: "Bekijk instellingen",
        allowRestrictedOrgs: "SURF-applicatie",
        allowRestrictedOrgsTooltip: "Deze applicatie mag gekoppeld worden aan samenwerkingen van een SURF-organisatie.",
        sirtfiCompliant: "Voldoet de applicatie aan Sirtfi?",
        sirtfiCompliantShort: "Sirtfi",
        sirtfiCompliantTooltip: "Het Security Incident Response Trust Framework for Federated Identity (Sirtfi) heeft als doel coördinatie van incidenten die organisatiegrenzen overstijgen te vereenvoudigen. Dit 'assurance framework' biedt een aantal 'assertions' waarmee een organisatie kan aangeven of ze Sirtfi compliant zijn.",
        codeOfConductCompliant: "Voldoet de applicatie aan CoCo?",
        codeOfConductCompliantShort: "CoCo",
        codeOfConductCompliantTooltip: "De GÉANT Data Protection Code of Conduct (DP-CoCo) is een initiatief om aan de eisen te voldoen van de EU Data Protection Directive voor het vrijgeven van persoonlijke attributen van een Identity Provider naar een Service Provider.",
        researchScholarshipCompliant: "Voldoet de applicatie aan R&S?",
        researchScholarshipCompliantShort: "R&S",
        researchScholarshipCompliantTooltip: "Applicatieaanbieders die voldoen aan de Research and Scholarship (R&S) Category zijn er geheel of gedeeltelijk op gericht onderzoeks- en onderwijssamenwerking en kennisuitwisseling te ondersteunen.",
        allowedOrganisations: "Toegestane organisaties",
        allowedOrganisationsPlaceholder: "Zoek organisaties",
        allowedOrganisationsTooltip: "Als er een organisatie wordt toegevoegd dan zijn alle andere organisaties automatisch uitgesloten van het gebruik van deze applicatie. Als deze applicatie door alle samenwerkingen mag worden gebruikt voeg dan geen organisaties toe.",
        contact_email: "Administratief e-mailadres",
        contact_emailPlaceholder: "Het administratieve e-mailadres van de contactpersoon van deze applicatie",
        contact_emailTooltip: "Dit e-mailadres wordt gebruikt om met de contactpersoon van de applicatie te communiceren. Idealiter is dit een functioneel e-mailadres, zoals applicatie_A_support@application.example.com.",
        contactEmailRequired: "Een administratief e-mailadres is verplicht als er geen applicatiebeheerders zijn",
        security_email: "Security e-mailadres",
        security_emailPlaceholder: "Het e-mailadres van de security-contactpersoon",
        security_emailTooltip: "Het security e-mailadres wordt gecontacteerd als er security-vragen zijn. Idealiter is dit een functioneel e-mailadres, zoals applicatie_A_security@application.example.com.",
        securityEmailRequired: "Security e-mailadres is verplicht",
        support_email: "Helpdesk voor eindgebruikers",
        support_emailPlaceholder: "Het e-mailadres of de website van de helpdesk voor deze applicatie",
        support_emailTooltip: "Het support e-mailadres of URL wordt getoond aan gebruikers van de applicatie",
        support_email_unauthorized_users: "Toon aan ongeautoriseerde gebruikers",
        support_email_unauthorized_usersPlaceholder: "Dit e-mailadres of de URL wordt getoond aan gebruikers wanneer ze proberen in te loggen op de applicatie maar ongeautoriseerd zijn, doorgaans doordat ze niet lid zijn van een gekoppelde samenwerking.",
        invitations: "Nodig applicatiebeheerders uit",
        status: {
            name: "Status",
            active: "Actief",
            in_active: "Inactief"
        },
        statusPlaceholder: "De status van de applicatie",
        alreadyExists: "Een applicatie met {{attribute}} {{value}} bestaat al.",
        required: "De applicatie heeft een {{attribute}} nodig.",
        deleteConfirmation: "Weet je zeker dat je applicatie {{name}} wil verwijderen? Dit kan niet teruggedraaid worden.",
        requestDeleteConfirmation: "Weet je zeker dat je een verzoek wilt indienen om deze applicatie {{name}} te verwijderen? Alls het verzoek wordt gehonoreerd dan kan dit niet meer teruggedraaid worden.",
        deleteWarning: "Je kan geen verzoek indienen om deze applicatie te verwijderen. Ontkoppel eerst de samenwerkingen op de 'Gekoppelde samenwerkingen' tab.",
        add: "Opslaan",
        request: "Registreer",
        update: "Wijzigen",
        delete: "Verwijderen",
        cancel: "Annuleren",
        flash: {
            created: "Applicatie {{name}} is aangemaakt.",
            createdServiceRequest: "Bedankt voor de applicatieregistratie van {{name}}. We bekijken je verzoek en van ons laten horen binnen een werkdag.<br/><br/>Het SURF Research Access Management support team",
            updated: "Applicatie {{name}} is bijgewerkt.",
            deleted: "Applicatie {{name}} is verwijderd.",
            requestDeleted: "Verzoek om applicatie {{name}} te verwijderen is verzonden",
            tokenAdded: "Nieuw token voor applicatie {{name}} is aangemaakt",
            tokenUpdated: "Token van applicatie {{name}} is bijgewerkt",
            tokenDeleted: "Token van applicatie {{name}} is verwijderd",
        },
        scim_token: {
            preTitle: "Om veiligheidsredenen kan het huidige SCIM token niet worden weergegeven. ",
            title: "Verander het SCIM token.",
            confirmation: "Voer het SCIM token in voor {{name}}. Het zal encrypted worden opgeslagen.",
            success: "SCIM token is veranderd.",
        },
        ldap: {
            preTitle: "Om veiligheidsredenen kan het huidige wachtwoord niet worden weergegeven. ",
            title: "Reset LDAP-wachtwoord.",
            confirmation: "Weet je zeker dat je het LDAP-wachtwoord wil resetten voor {{name}}?",
            success: "LDAP wachtwoord is gereset.",
            info: "Sla het wachtwoord veilig op. Hierna is het wachtwoord niet meer te zien.",
            copy: "Kopieer",
            close: "Sluit",
            section: "LDAP-configuratie",
            sectionTooltip: "De LDAP-configuratie van de SRAM platform LDAP server voor het synchroniseren met de LDAP server van de applicatie.",
            url: "LDAP URL",
            urlTooltip: "De URL van de LDAP-server van het platform. Gebruik deze op de applicatie of eigen LDAP-server om te synchroniseren",
            username: "Bind DN",
            usernameTooltip: "De bind DN om bij de LDAP-server van het platform te authenticeren",
            basedn: "Base DN",
            basednTooltip: "De base DN van de LDAP-server van het platform",
            ldapClient: "LDAP client",
            ldapEnabledTooltip: "Deze applicatie mag de SRAM LDAP server bevragen",
            ldapDisclaimer: "LDAP is uitgeschakeld",
        },
        aup: {
            title: "Reset AUP",
            confirmation: "Resetten zorgt ervoor dat alle gebruikers van deze applicatie '{{name}}' de AUP opnieuw moeten accepteren. Wil je dat?",
            flash: "De acceptatie van de AUP voor applicatie {{name}} is gereset voor alle gebruikers"
        },
        pamWebSSO: {
            pamWebSSODisclaimer: "PAM web login is uitgeschakeld"
        },
        contacts: "Contact",
        leave: "Verlaat applicatie",
        confirmation: {
            leave: "Weet je zeker dat je niet meer de admin van deze applicatie wil zijn? Je kan dit niet ongedaan maken.",
            lastAdminWarning: "Je laat deze applicatie achter zonder beheerders. Hierna kan het beheer van de applicatie alleen worden uitgevoerd door de beheerders van SRAM",
            lastAdminWarningUser: "Je bent de laatste beheerder in deze applicatie. Na je vertrek kan het beheer van de applicatie alleen worden uitgevoerd door de beheerders van SRAM",
        },
        viewAsServiceAdmin: "Bekijk als applicatiebeheerder",
        viewAsPlatformAdmin: "Bekijk als platformbeheerder",
        fakeServiceAdmin: "Je bent applicatiebeheerder (not)",
        addMe: "Maak me applicatiebeheerder",
        access: "Toegang",
        policies: "Beleid",
        policiesSupport: "Beleid & Support",
        tokens: "My Tokens",
        policyCompliance: "Beleid voldoet aan",
        login: "Inloggen",
        support: "Support voor de eindgebruiker",
        supportShort: "Support",
        supportContactPre: "Voor support neem contact op met ",
        or: " of ",
        visitWebsite: "bezoek de website",
        supportThroughWebsitePre: "Voor support ",
        supportThroughWebsiteLink: "bezoek de website.",
        admins: "E-mailadressen beheerders",
        adminContact: "Contactpersoon beheer",
        noSupport: "Applicatie {{name}} biedt geen hulp aan eindgebruikers.",
        launch: "Open",
        connectionDetails: "Details",
        contactSupport: "Contact & hulp",
        providingOrganisation: "Aanbiedende organisatie",
        providingOrganisationPlaceholder: "Naam van de aanbiedende organisatie",
        protocol: "Webgebaseerd protocol",
        protocols: {
            openIDConnect: "OpenID Connect",
            saml2URL: "SAML2 URL",
            saml2File: "SAML2-bestand",
            none: "Geen, de applicatie heeft geen web interface"
        },
        protocolsShort: {
            openIDConnect: "OIDC",
            saml2URL: "SAML2",
            saml2File: "SAML2",
            none: "None"
        },
        openIDConnectRedirects: "OpenID Connect redirect URL's",
        openIDConnectRedirectsPlaceholder: "https://applicatie.com/redirect",
        openIDConnectRedirectsTooltip: "De redirect URL nadat de user is ingelogd; moet volledig overeenkomen.",
        samlMetadata: "SAML metadata",
        samlMetadataURL: "SAML metadata URL",
        samlMetadataPlaceholder: "https://metadata",
        samlMetadataUpload: "Upload SAML metadata",
        noneInfo: "Andere protocollen kunnen later worden toegevoegd",
        commentsTooltip: "Je opmerkingen aan de beheerders die je applicatie-aanvraag behandelen",
        comments: "Optionele opmerkingen",
        commentsPlaceholder: "Opmerkingen",
        commentsHeader: "Opmerkingen over dit verzoek",
        commentsAttribute: "motivatie",
        connectionSettings: {
            connectQuestion: "1. Wie mag inloggen op de applicatie?",
            coMembers: "Gekoppelde samenwerkingen",
            institutionSelection: "Ik kies hieronder wie mag koppelen",
            crmOrganisationMembers: "Samenwerkingen & mijn organisatie",
            none: "Geen",
            noneText: "Samenwerkingen mogen niet koppelen",
            noOne: "Niemand",
            later: "Ik ga dit later instellen",
            everyOne: "Iedereen",
            everyOneText: "Geen lidmaatschap nodig",
            whichInstitutionsQuestion: "2. Van welke organisaties mogen samenwerkingen koppelen?",
            allInstitutions: "Alle organisaties",
            allCOWelcome: "Alle samenwerkingen zijn welkom",
            onlySome: "Een paar",
            specificInstitutions: "Ik kies hieronder van welke organisaties",
            directlyConnectQuestion: "3. Kan een samenwerking meteen koppelen?",
            directConnect: "Samenwerkingen kunnen meteen koppelen",
            manuallyApprove: "Ik wil koppelverzoeken handmatig goedkeuren",
            depends: "Hangt er vanaf",
            settingsPerInstitution: "Ik kies hieronder per organisatie"
        },
        sweep: {
            test: "Test SCIM",
            testTooltip: "Test het SCIM endpoint en het SCIM token",
            success: "De SCIM connectie test naar {{url}} was succesvol",
            failure: "De SCIM connectie test naar {{url}} was niet succesvol.",
            response: "Antwoord van het SCIM endpoint:",
            saveBeforeTest: "Wijzigen applicatie",
            saveBeforeTestQuestion: "Je hebt wijzigingen gemaakt in de SCIM configuratie. Wil je deze wijzigingen eerst opslaan voordat je de SCIM connectie gaat testen?"
        },
        openIDConnectGrants: "OpenID Connect grants",
        openIDConnectGrantsPlaceholder: "Choose grant(s)",
        openIDConnectGrantsTooltip: "Grant types are the way to specify how a Relying Party will interact with the OIDC server",
        isPublicClient: "Public client",
        isPublicClientTooltip: "Public clients are not required to authenticate, but must use the Proof key for Code Exchange (PKCE) authorisation",
        grants: {
            authorization_code: "Authorization code",
            implicit: "Implicit (deprecated)",
            refresh_token: "Refresh token",
            client_credentials: "Client credentials",
            device_code: "Device code"
        },
        samlMetaData: {
            acs_binding: "ACSBinding",
            acs_location: "ACSLocation",
            entity_id: "EntityID",
            organization_name: "Organization",
            unknown: "Not present",
            missingAttributes: "Incomplete SAML meta data"
        },
        oidc: {
            oidcClientSecretDisclaimer: "Het secret wordt slechts eenmaal getoond. Sla het secret op en bewaar het veilig.Bij verlies zal je een nieuwe secret moeten aanvragen.",
            oidcClientSecret: "OpenID client secret",
            oidcClientSecretTooltip: "Het secret wat je gebruikt om een OIDC token aan te vragen",
            preTitle: "Om veiligheidsredenen kan het huidige secret niet worden weergegeven. ",
            title: "Reset OIDC-client secret..",
            confirmation: "Weet je zeker dat je het OIDC-client secret wil resetten voor {{name}}?",
            success: "OIDC-secret is gereset.",
            info: "Sla het secret veilig op. Hierna is het secret niet meer te zien.",
            copy: "Kopieer",
            close: "Sluit",
            section: "OIDC configuratie",
            sectionTooltip: "De configuratie van de OIDC configuratie om OIDC login met deze applicatie in te schakelen.",
            oidcClient: "OIDC client",
            oidcEnabledTooltip: "Configureer deze applicatie in te loggen met het OIDC protocol",
            oidcDisclaimer: "OIDC protocol is uitgeschakeld",
            oidcDisabledExclusivity: "OIDC protocol kan niet worden aangezet, omdat het SAML protocol actief is"
        },
        saml: {
            section: "SAML configuratie",
            sectionTooltip: "De SAML protocol configuratie om SAML login in te schakelen voor deze applicatie.",
            samlClient: "SAML protocol",
            samlEnabledTooltip: "Configureer deze applicatie in te loggen met het SAML protocol",
            samlDisclaimer: "SAML protocol is uitgeschakeld",
            samlError: "Of de SAML metadata URL of een SAML metadata bestand zijn verplicht",
            samlDisabledExclusivity: "SAML protocol kan niet worden aangezet, omdat het OIDC protocol actief is"
        },
        export: {
            exportDisabled: "Deze applicatie zal niet worden extern worden gesynchroniseerd, omdat deze feature uitgeschakeld is in deze omgeving",
            noExport: "Deze applicatie zal niet worden extern worden gesynchroniseerd, omdat zowel OIDC als SAML uitgeschakeld zijn.",
            export: "Deze applicatie zal extern worden gesynchroniseerd, omdat OIDC of SAML ingeschakeld zijn.",
            lastExportDate: "Laatse synchronisatie datum",
            notExported: "Nog niet geëxporteerd",
            lastExportStatus: "Laatse synchronisatie status",
            successful: "Succesvol gesynchroniseerd",
            failure: "Synchronisatie fout",
            externalIdentifier: "Externe identifier",
            externalVersion: "Externe versie",
            externalLink: "Exerne link",
            externalLinkValue: "{{base_url}}/metadata/sram/{{external_identifier}}"
        }
    },
    organisation: {
        title: "Nieuwe organisatie toevoegen",
        subTitle: "Wijzig de omschrijving van de organisatie.",
        actions: "",
        about: "Over onze organisatie",
        name: "Naam",
        namePlaceHolder: "De unieke naam van de organisatie",
        invitations: "Organisatiebeheerders uitnodigen",
        category: "Categorie",
        categoryTooltip: "Type instelling",
        tenantPlaceHolder: "De unieke tenant-/organisatie-identifier die de organisatie verbindt met een instelling",
        shortName: "Korte naam",
        shortNamePlaceHolder: "Korte naam van de organisatie",
        shortNameTooltip: "De korte name wordt gebruikt voor LDAP-applicaties (zoals Linux directory-namen).<br/><br/>" +
            "Alleen getallen, alfanumerieke karakers en de lage streep zijn toegstaan.",
        identifier: "Identifier",
        identifierTooltip: "Gegenereerde, unieke en niet aanpasbare identifier van een organisatie die wordt gebruikt als identifier voor externe systemen",
        description: "Omschrijving",
        descriptionPlaceholder: "De omschrijving van de organisatie is zichtbaar voor iedereen",
        servicesRestricted: "SURF-organisatie",
        servicesRestrictedTooltip: "Samenwerkingen van een SURF-organisatie kunnen alleen SURF-applicaties koppelen.",
        schacHomeOrganisationShortName: "Organisatiedomein",
        schacHomeOrganisationShortNames: "Organisatiedomeinen",
        schacHomeOrganisation: "Organisatiedomeinnamen",
        schacHomeOrganisationPlaceholder: "De domeinnamen van de organisatie",
        schacHomeOrganisationTooltip: "De domeinnamen waarmee gebruikers van deze organisatie inloggen. Deze gebruikers kunnen samenwerkingen aanmaken of aanvragen bij je organisatie.",
        collaborationCreationAllowed: "Gebruikers kunnen zonder goedkeuring een samenwerking aanmaken",
        collaborationCreationAllowedTooltip: "Sta toe dat gebruikers van de organisatie samenwerkingen aanmaken zonder goedkeuring van de organisatiebeheerder of -manager",
        serviceConnectionRequiresApproval: "Verzoek voor applicatie koppeling moet worden goedgekeurd door de organisatie beheerder",
        serviceConnectionRequiresApprovalTooltip: "Indien geselecteerd, dan moet een verzoek voor een applicatie koppeling eerst worden goedgekeurd door de organisatie beheerder",
        accepted_user_policyPlaceholder: "De acceptable use policy (AUP) van de organisatie.",
        accepted_user_policyTooltip: "Gebruikers van buiten de organsatie moeten deze AUP accepteren wanneer ze voor het eerste lid worden van een samenwerking van deze organisatie.",
        crmId: "Organisatie (CRM ID)",
        crmIdPlaceholder: "CRM identifier, e.g. A80A11E8-1998-4206-9F83-24ECA6336615",
        crmIdTooltip: "De organisatie gekoppeld aan deze applicatie door de CRM identifier",
        collaborationCreationLabel: "Gebruikers van deze domeinen",
        collaborationCreationIsAllowed: "Kunnen samenwerkingen aanmaken",
        collaborationCreationNotAllowed: "Kunnen samenwerkingen aanvragen",
        singleSchacHome: "dit domein",
        multipleSchacHome: "één van deze domeinen",
        invalidSchacHome: "{{schac}} is niet een valide domein naam",
        duplicateSchacHome: "Organisatie domein namen moeten uniek zijn",
        created: "Aangemaakt op",
        message: "Optioneel bericht",
        messagePlaceholder: "Bericht voor de beheerders",
        messageTooltip: "Deze tekst voegen we in de e-mail toe waarmee we de beheerders uitnodigen.",
        alreadyExists: "Er bestaat al een organisatie met {{attribute}} {{value}}.",
        required: "{{attribute}} is een verplicht veld voor een organisatie.",
        administrators: "Beheerders",
        administratorsPlaceholder: "Beheerders uitnodigen per e-mail",
        filePlaceholder: "Kies een csv- of txt-bestand",
        fileImportResult: "{{nbr}} e-mails geīmporteerd uit {{fileName}}.",
        fileExtensionError: "Alleen bestanden met een .csv extensie zijn toegestaan.",
        administratorsTooltip: "Beheerders van een organisatie kunnen samenwerkingen aanmaken binnen hun organisatie. Vul e-mailadressen van uit te nodigen mensen in, gescheiden door een komma, spatie of punt-komma, of voeg ze stuk-voor-stuk toe via de enter toets.",
        role: "Rol",
        new: "Nieuwe organisatie",
        admin: "Beheerder",
        manager: "Manager",
        member: "Lid",
        organisationRoles: {
            admin: "Organisatiebeheerder",
            manager: "Organisatiemanager",
            undefined: ""
        },
        organisationShortRoles: {
            admin: "Beheerder",
            manager: "Manager",
            undefined: ""
        },
        yourself: "{{name}} (jijzelf dus)",
        logo: "Organisatielogo",
        deleteConfirmation: "Weet je zeker dat je deze organisatie wil verwijderen? Dit kan niet teruggedraaid worden.",
        flash: {
            created: "Organisatie {{name}} is aangemaakt."
        },
        onBoarding: {
            label: "Bericht voor gebruikers bij aanvragen of aanmaken van een samenwerking",
            invalid: "Je moet het bericht aan gebruikers aanpassen",
            tooltip: "Dit bericht wordt getoond aan gebruikers van de organisatie wanneer ze een samenewerking aanvragen of aanmaken",
            template: "Hoi!,\n\n" +
                "Je kunt een samenwerking **aanmaken/aanvragen**. Omschrijf waarom je deze samenwerking wilt gaan gebruiken. We kunnen contact met je opnemen over je aanvraag.",
            tabs: {
                write: "Markdown",
                preview: "Voorbeeld"
            }
        }
    },
    organisationDetail: {
        backToOrganisations: "Terug naar mijn organisaties",
        backToOrganisationDetail: "Terug naar mijn organisatie {{name}}",
        title: "Omschrijving organisatie {{name}}",
        back: "Terug naar mijn organisaties",
        members: "Leden van {{name}}",
        noMembers: "Deze organisatie heeft geen leden.",
        mailMembers: "E-mail alle leden",
        mailMembersTooltip: "E-mail alle leden van deze organisatie",
        invitations: "Uitnodigingen voor {{name}}",
        collaborationRequests: "Aanvragen voor samenwerkingen voor {{name}}",
        searchPlaceHolder: "Zoek leden",
        invite: "Nodig nieuwe leden uit",
        newApiKey: "Voeg een organisatie-API-token toe",
        noInvitations: "Geen openstaande uitnodigingen",
        noCollaborationRequests: "Geen openstaande samenwerkingsaanvragen",
        member: {
            user__name: "Naam",
            user__email: "E-mail",
            user__uid: "UID",
            user__suspended: "Geschorst",
            role: "Rol",
            created_at: "Sinds",
            actions: ""
        },
        invitation: {
            actions: "",
            invitee_email: "E-mail genodigde",
            user__name: "Uitgenodigd door",
            intended_role: "Role",
            expiry_date: "Verloopt",
            noExpires: "N.v.t.",
            message: "Bericht",
        },
        collaborationRequest: {
            actions: "",
            name: "Naam",
            short_name: "Korte naam",
            requester__name: "Aanvrager",
            message: "Motivatie"
        },
        collaboration: {
            name: "Naam",
            description: "Omschrijving",
            short_name: "Korte naam",
            global_urn: "Platform identifier",
            accepted_user_policy: "AUP",
            created_at: "Sinds",
            actions: "",
            link: ""
        },
        service: {
            name: "Naam",
            entity_id: "Entity ID",
            abbreviation: "Korte naam",
            description: "Omschrijving",
            actions: "",
        },
        apiKeys: "Organisatie-API-tokens van {{name}}",
        collaborations: "Samenwerkingen van {{name}}",
        noCollaborations: "Deze samenwerking heeft geen samenwerkingen.",
        newCollaboration: "Nieuwe samenwerking",
        services: "Applicaties gekoppeld aan {{name}}",
        noServices: "Er zijn geen standaardapplicaties voor deze organisatie.",
        configureServices: "Configureer applicaties",
        searchPlaceHolderCollaborations: "Zoek samenwerkingen",
        update: "Opslaan",
        delete: "Verwijderen",
        deleteMemberConfirmation: "Weet je zeker dat je deze organisatiebeheerders en -managers wil verwijderen?",
        deleteSingleMemberConfirmation: "Weet je zeker dat je deze beheerder wil verwijderen?",
        deleteSingleInvitationConfirmation: "Weet je zeker dat je deze uitnodiging wil verwijderen?",
        deleteYourselfMemberConfirmation: "Weet je zeker dat je deze organisatie wil verlaten? Je kan dit niet terugdraaien.",
        deleteApiKeyConfirmation: "Weet je zeker dat je dit organisatie-API-token wil verwijderen?",
        deleteCollaborationConfirmation: "Weet je zeker dat je samenwerking {{name}} wil verwijderen? Dit kan niet teruggedraaid worden.",
        activateMemberConfirmation: "Weet je zeker dat je gebruiker {{name}} wil her-activeren? We zullen de gebruiker een e-mail sturen met activatie-link.",
        flash: {
            updated: "Organisatie {{name}} is bijgewerkt.",
            deleted: "Organisatie {{name}} is verwijderd.",
            entitiesDeleted: "Lidmaatschap(pen) / uitnodigingen zijn verwijderd",
            memberDeleted: "Lidmaatschap van {{name}} is verwijderd.",
            apiKeyDeleted: "Organisatie-API-token verwijderd.",
            collaborationDeleted: "Samenwerking {{name}} is verwijderd.",
            memberActivated: "Aan lid {{name}} is per e-mail een activatie-link verstuurd.",
            memberUpdated: "De rol of lidmaatschap van {{name}} is bijgewerkt naar {{role}}.",
        },
        preview: "Preview",
        details: "Details",
        gone: {
            member: "Dit lidmaatschap bestaat niet meer. Na het sluiten van deze pop-up worden de lidmaatschappen vernieuwd.",
            invitation: "Deze uitnodiging is al geaccepteerd/afgewezen en bestaat niet meer. Na het sluiten van deze pop-up worden de uitnodigingen ververst."
        }
    },
    joinRequest: {
        title: "Verzoek van {{requester}} om lid te worden van samenwerking {{collaboration}}",
        message: "Onderbouwing",
        messageTooltip: "De onderbouwing van {{name}} voor dit verzoek",
        collaborationName: "Samenwerking",
        userName: "Gebruiker",
        decline: "Afwijzen",
        accept: "Goedkeuren",
        declineConfirmation: "Weet je zeker dat je het verzoek wil afwijzen?",
        deleteConfirmation: "Weet je zeker dat je het verzoek wil verwijderen?",
        rejectionReason: "Voeg de reden voor de afwijzing toe",
        rejectionReasonLabel: "Reden voor afwijzing",
        rejectionReasonNote: "Let op dat de reden wordt opgenomen in de e-mail naar de aanvrager",
        why: "Waarom wil je lid worden van deze samenwerking?",
        invited: "Ik ben uitgenodigd om lid te worden",
        projectMember: "Ik ben lid van dit project",
        other: "Anders, gaarne uitleggen",
        flash: {
            declined: "Verzoek voor lidmaatschap van samenwerking {{name}} is afgewezen.",
            accepted: "Verzoek voor lidmaatschap van samenwerking {{name}} is goedgekeurd.",
            deleted: "Verzoek voor lidmaatschap van samenwerking {{name}} is verwijderd.",
            notFound: "Dit verzoek voor lidmaatschap is reeds goedgekeurd of afgewezen.",
            alreadyMember: "Je bent al lid van de samenwerking {{name}} en kan daarom deze uitnodiging niet accepteren."
        }
    },
    organisationInvitation: {
        title: "Uitnodiging om lid te worden van {{organisation}}",
        backToOrganisationDetail: "Terug naar mijn organisatie {{name}}",
        createTitle: "Uitnodigingen versturen om lid te worden van organisatie {{organisation}}",
        organisationName: "Naam",
        organisationDescription: "Omschrijving",
        organisationAdministrators: "Beheerders",
        requiredAdministrator: "Er is minimaal één e-mailadres van een beheerder nodig voor een uitnodiging.",
        role: "Rol",
        expiryDate: "Uitnodiging verloopt op",
        expiryDateTooltip: "De einddatum van de uitnodiging, waarna de uitnodiging niet meer geldig is.",
        message: "Bericht",
        messageTooltip: "{{name}} heeft je uitgenodigd met de volgende tekst:",
        fileImportResult: "{{nbr}} e-mailadressen geīmporteerd uit {{fileName}}.",
        fileExtensionError: "Alleen bestanden met .csv extensie zijn toegestaan.",
        inviter: "Uitnodiger",
        decline: "Afslaan",
        accept: "Accepteren",
        invite: "Uitnodigen",
        delete: "Verwijderen",
        resend: "Opnieuw verzenden",
        declineInvitation: "Weet je zeker dat je de uitnodiging wil afslaan?",
        deleteInvitation: "Weet je zeker dat je de uitnodiging wil verwijderen?",
        resendInvitation: "Weet je zeker dat je de uitnodiging opnieuw wil versturen?",
        resendInvitations: "Weet je zeker dat je alle geselecteerde uitnodigingen opnieuw wil versturen?",
        expired: "Deze uitnodiging is verlopen op {{expiry_date}} en kan niet meer geaccepteerd worden.",
        expiredAdmin: "Deze uitnodiging is verlopen op {{expiry_date}}. Verstuur deze opnieuw om de verloopdatum 14 dagen vooruit te zetten.",
        flash: {
            inviteDeclined: "Uitnodiging voor organisatie {{name}} is afgewezen.",
            inviteDeleted: "Uitnodiging voor organisatie {{name}} is verwijderd.",
            inviteResend: "Uitnodiging voor organisatie {{name}} is opnieuw verzonden.",
            invitesResend: "Uitnodigingen voor organisatie {{name}} zijn opnieuw verzonden.",
            inviteAccepted: "Uitnodiging voor organisatie {{name}} is geaccepteerd.",
            created: "Uitnodiging(en) voor organisatie {{name}} aangemaakt.",
            alreadyMember: "De uitnodiging kon niet worden geaccepteerd omdat je al lid bent van deze organisatie.",
            notFound: "Deze uitnodiging is reeds geaccepteerd of afgewezen. Als je denkt dat er een probleem is, neem dan contact op met je beheerder (of met ondersteuning op sram-support@surf.nl)."
        },
    },
    apiKeys: {
        title: "Nieuw API-token voor {{organisation}}",
        info: "Met een organisatie-API-token kan de <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"{{base_url}}/apidocs/\"'>Application Programmer Interface (API)</a> worden gebruikt.",
        backToOrganisationDetail: "Terug naar mijn organisatie {{name}}",
        secretDisclaimer: "Het token wordt slechts eenmaal getoond. Sla het token op en bewaar het veilig.<br><br>Bij verlies zal je het token moeten verwijderen en een nieuwe moeten aanmaken.",
        secret: "Organisatie-API-token",
        secretValue: "One-way hashed token",
        secretTooltip: "Het token voor in de authorization header",
        description: "Omschrijving",
        descriptionPlaceHolder: "Omschrijving voor dit organisatie-API-token",
        descriptionTooltip: "Een optionele omschrijving van het doel van dit organisatie-API-token",
        flash: {
            created: "Organisatie-API-token voor organisatie {{name}} aangemaakt.",
        },
        submit: "Opslaan",
        required: "{{attribute}} is een verplicht veld voor een API token",
    },
    invitation: {
        title: "Uitnodiging om lid te worden van samenwerking {{collaboration}}",
        createTitle: "Verstuur uitnodigingen om lid te worden van samenwerking {{collaboration}}",
        collaborationName: "Naam",
        collaborationDescription: "Omschrijving",
        collaborationAdministrators: "Beheerders",
        invitees: "Genodigden",
        inviteesPlaceholder: "E-mailadressen genodigden",
        inviteesTooltip: "Deze tekst nemen we op in de e-mail waarmee we deelnemers uitnodigen",
        intendedRole: "Rol",
        intendedRoleTooltip: "De rechten die alle genodigden krijgen. Beheerders kunnen de gegevens van de samenwerking wijzigen, applicaties koppelen en leden uitnodigen.",
        intendedRoleOrganisation: "Rol",
        intendedRoleTooltipOrganisation: "De rechten die alle genodigden krijgen. Organisatiebeheerders kunnen de gegevens van de organisatie wijzigen en beheerders en managers uitnodigen. Managers kunnen alleen samenwerkingen beheren.",
        invitee_email: "E-mail genodigde",
        groupsPlaceHolder: "Selecteer groepen",
        groupsTooltip: "De groepen waar alle genodigden lid van worden.",
        groups: "Groepslidmaatschap",
        requiredEmail: "Je dient minimaal één e-mailadres op te geven waar je de uitnodiging om lid te worden naartoe wil sturen.",
        existingInvitation: "Er is al een bestaande uitnodigingen voor: {{emails}}",
        existingInvitations: "Er zijn al bestaande uitnodigingen voor: {{emails}}",
        requiredRole: "Je moet een rol kiezen voor het uit te nodigen lid.",
        requiredExpiryDate: "De geldigheidsdatum van de uitnodiging is verplicht",
        message: "Optioneel bericht",
        messagePlaceholder: "Bericht aan de beheerders",
        messageTooltip: "De boodschap nemen we op in de e-mail waarmee beheerders worden uitgenodigd.",
        inviteesMessagesTooltip: "Voer e-mailadressen in, gescheiden door een komma, spatie of puntkomma of voer ze stuk voor stuk in met een enter. Je kunt ook een csv-bestand plakken met daarin regelgescheiden e-mailadressen.",
        invalidEmails: "Ongeldige e-mailadressen verwijderd: {{emails}}.",
        appendAdminNote: "Let op: de genodigen zullen <strong>beheerder</strong> worden na het accepteren van de uitnodiging.",
        inviteesMessagePlaceholder: "Bericht aan genodigden",
        inviter: "Uitnodiger",
        decline: "Afslaan",
        accept: "Accepteren",
        delete: "Verwijderen",
        resend: "Opnieuw sturen",
        invite: "Uitnodigen",
        declineInvitation: "Weet je zeker dat je de uitnodiging wil afslaan?",
        deleteInvitation: "Weet je zeker dat je de uitnodiging wil verwijderen?",
        resendInvitation: "Weet je zeker dat je de uitnodiging opnieuw wil versturen?",
        expired: "De uitnodiging is op {{expiry_date}} verlopen en kan niet meer worden gebruikt.",
        expiredAdmin: "Deze uitnodiging is op {{expiry_date}} verlopen. Verstuur hem opnieuw om de verloopdatum 14 dagen in te toekomst te zetten.",
        filePlaceholder: "Selecteer een csv of txt bestand",
        fileImportResult: "{{nbr}} e-mailadressen ingelezen uit {{fileName}}.",
        fileExtensionError: "Alleen bestanden met een .csv extensie zijn toegestaan.",
        expiryDate: "Uitnodiging geldig tot",
        expiryDateTooltip: "Datum waarop de uitnodiging vervalt en deze niet meer gebruikt worden.",
        membershipExpiryDate: "Lidmaatschap verloopt op",
        membershipExpiryDateTooltip: "Datum waarop het lidmaatschap verloopt en de gebruiker de applicaties gekoppeld aan deze samenwerking niet meer kan gebruiken.",
        flash: {
            inviteDeclined: "Uitnodiging voor samenwerking {{name}} is afgewezen.",
            inviteAccepted: "Uitnodiging voor samenwerking {{name}} is geaccepteerd.",
            inviteDeleted: "Uitnodiging voor samenwerking {{name}} is verwijderd.",
            inviteResend: "Uitnodiging voor samenwerking {{name}} opnieuw verstuurd.",
            created: "Uitnodigingen voor samenwerking {{name}} zijn aangemaakt.",
            alreadyMember: "Je bent al lid van de samenwerking {{name}} en kan daarom deze uitnodiging niet accepteren.",
        },
    },
    collaborationServices: {
        title: "Applicaties voor samenwerking {{name}}",
        connectedServices: "Met {{name}} gekoppelde applicaties",
        noServices: "Deze samenwerking heeft geen gekoppelde applicaties.",
        noServiceConnectRequests: "Deze samenwerking heeft geen openstaande applicatiekoppelverzoeken.",
        searchServices: "Zoek, kies en voeg applicaties toe aan samenwerking {{name}}",
        deleteServiceTooltip: "Maak deze applicatie ontoegankelijk voor samenwerking {{name}}. <strong>Let op</strong>: de applicatie wordt <strong>niet</strong> verwijderd, alleen onbeschikbaar voor groepen van deze samenwerking.",
        notAllowedOrganisation: "Applicatie '{{service}}' kan niet toegoegd worden aan samenwerking '{{collaboration}}'. De configuratie van de applicatie laat een koppeling met organisation '{{organisation}}' niet toe.",
        automaticConnectionNotAllowed: "Applicatie '{{service}}' kan niet automatisch worden toegevoegd aan de samenwerking '{{collaboration}}'. De applicatie is zo geconfigureerd dat er eerst een applicatiekoppelverzoek moet worden gedaan. Schrijf je motivatie voor de koppeling hieronder en verstuur het formulier.",
        motivation: "Motivatie",
        motivationPlaceholder: "Je motivatie om deze applicatie te gebruiken",
        send: "Verzenden",
        flash: {
            added: "Applicatie {{service}} is gekoppeld aan samenwerking {{name}}.",
            deleted: "Applicatie {{service}} is ontkoppeld van samenwerking {{name}}.",
            send: "Applicatiekoppelverzoek voor {{service}} is verzonden.",
        },
        service: {
            open: "",
            actions: "",
            name: "Naam",
            entity_id: "Entity ID",
            abbreviation: "Korte naam",
            description: "Omschrijving"
        },
        serviceConnectionRequest: {
            actions: "",
            resend: "",
            service: "Applicatie",
            requester: "Aanvrager",
            created_at: "Datum",
            message: "Motivatie"
        },
        actionsTooltip: "Verwijder het applicatiekoppelverzoek. Het verzoek kan daarna niet meer geaccepteerd of afgewezen worden.",
        serviceConnectionRequests: "Applicatiekoppelverzoeken",
        serviceConnectionRequestDeleted: "Het verzoek om applicatie {{service}} te koppelen met samenwerking {{collaboration}} is verwijderd.",
        serviceConnectionRequestResend: "Het verzoek om applicatie {{service}} te koppelen met samenwerking {{collaboration}} is opnieuw verstuurd.",
        serviceRestrictedInfo: "Deze samenwerking is beperkt in de applicaties. Alleen de platformbeheerder kan applicaties configureren die door deze samenwerking worden gebruikt.",
        serviceDeleteConfirmation: "Weet je zeker dat je deze applicatie uit de samenwerking {{collaboration}} wil verwijderen?",
        serviceConnectionRequestDeleteConfirmation: "Weet je zeker dat je dit koppelverzoek wil verwijderen?",
        serviceConnectionRequestResendConfirmation: "Weet je zeker dat je dit koppelverzoek opnieuw wil versturen?",
    },
    organisationServices: {
        title: "Applicaties voor organisatie {{name}}",
        connectedServices: "Gekoppelde applicaties aan {{name}}",
        noServices: "Deze organisatie heeft geen gekoppelde applicaties.",
        searchServices: "Zoek, kies en voeg applicaties toe aan organisatie {{name}}",
        deleteServiceTooltip: "Maak deze applicatie ontoegankelijk voor samenwerking {{name}}. <strong>Let op</strong>: de applicatie wordt niet verwijderd, alleen onbeschikbaar voor de samenwerkingen van deze organisatie.",
        noAccessAllowed: "Deze applicatie kan niet worden verplicht voor alle samenwerkingen omdat geen collaborations een koppeling kunnen maken met deze applicatie\n",
        notAllowedOrganisation: "Deze applicatie kan niet worden verplicht voor alle samenwerkingen omdat deze applicatie automatische verbindingen niet toestaat. Aansluitingen kunnen nog steeds worden aangevraagd door individuele samenwerkingen in je organisatie.",
        notEnabledOrganisation: "Deze applicatie kan niet worden verplicht voor alle samenwerkingen omdat deze applicatie toegang voor deze organisatie niet toestaat.",
        serviceRestrictedOrganisation: "Deze applicatie kan niet worden verplicht voor alle samenwerkingen omdat deze organisatie alleen SURF-applicaties mag koppelen.",
        flash: {
            added: "Applicatie {{service}} is toegevoegd aan organisatie {{name}}.",
            deleted: "Applicatie {{service}} is onbeschikbaar gemaakt voor organisatie {{name}}.",
        },
        service: {
            open: "",
            actions: "",
            name: "Naam",
            entity_id: "Entity ID",
            description: "Omschrijving"
        },
        serviceDeleteConfirmation: "Weet je zeker dat je deze applicatie wil verwijderen uit organisatie {{organisation}}",
    },
    groups: {
        title: "Groepen in samenwerking {{name}}",
        servicesTitle: "Applicaties voor groep {{name}}",
        membersTitle: "Leden van groep {{name}}",
        membersSubTitle: "Leden van deze groep",
        noMembers: "Deze groep heeft geen leden.",
        invitationsTitle: "Openstaande uitnodigingen om lid te worden van deze groep",
        pendingInvite: "Openstaande uitnodiging",
        noInvites: "Deze groep heeft geen openstaande uitnodigingen.",
        titleNew: "Maak nieuwe groep",
        titleUpdate: "Werk groep {{name}} bij",
        titleReadOnly: "Groep {{name}}",
        backToCollaborationGroups: "Terug naar de groepen van samenwerking {{name}}",
        new: "Nieuwe groep",
        searchPlaceHolder: "Zoek groepen",
        name: "Naam",
        namePlaceholder: "Naam van de groep",
        short_name: "Korte naam",
        shortNamePlaceHolder: "Korte naam van de groep",
        shortNameTooltip: "De korte naam van een applicatiegroep wordt gebruikt (1) voor groepen aangemaakt in samenwerkingen, voorafgegaan door de korte naam van de applicatie, gescheiden door een koppelteken '-' en (2) in de challenge URL gebruikt door PAM web login.<br/><br/>" +
            "Alleen getallen, alfanumerieke karakers en de lage streep zijn toegstaan.",
        identifier: "Identifier",
        identifierTooltip: "Gegenereerde, unieke en niet aanpasbare identifier van een groep die wordt gebruikt als identifier voor externe systemen",
        collaboration: "Samenwerking",
        autoProvisionMembers: "Maak leden van de samenwerking automatisch lid",
        autoProvisionMembersTooltip: "Vink aan om automatisch alle bestaande leden en nieuwe leden toe te voegen aan deze groep",
        global_urn: "Platform identifier",
        globalUrnTooltip: "Binnen het platform unieke en onaanpasbare identifier, gebaseerd op de korte naam van de organisatie, de samenwerking en de groep.",
        alreadyExists: "Een groep met {{attribute}} {{value}} bestaat al.",
        required: "{{attribute}} is een verplicht veld voor een groep",
        uri: "URI",
        uriPlaceholder: "URI van de groep",
        description: "Omschrijving",
        descriptionPlaceholder: "Omschrijving van de groep",
        status: "Status",
        statusPlaceholder: "De status van de groep",
        actions: "",
        open: "",
        deleteConfirmation: "Weet je zeker dat je groep {{name}} wil verwijderen?",
        removeServiceConfirmation: "Weet je zeker dat je de applicatie {{name}} wil verwijderen uit deze groep?",
        removeServiceConfirmationDetails: "De onderstaande applicatiespecifieke informatie zal worden verwijderd:",
        removeMemberConfirmation: "Weet je zeker dat je lid {{name}} wil verwijderen uit deze groep?",
        removeMemberConfirmationDetails: "De onderstaande informatie van deze gebruiker zal worden verwijderd.",
        user: "Gebruiker {{name}}",
        attributes: "Attributen",
        statusValues: {
            active: "Actief",
            in_active: "Inactief"
        },
        add: "Maak aan",
        update: "Opslaan",
        delete: "Verwijder",
        cancel: "Annuleer",
        flash: {
            created: "Groep {{name}} is aangemaakt.",
            updated: "Groep {{name}} is bijgewerkt.",
            deleted: "Groep {{name}} is verwijderd.",
            deletedGroups: "Groepen verwijderd",
            addedService: "Applicatie {{service}} toegevoegd aan groep {{name}}.",
            deletedService: "Applicatie {{service}} ontkoppeld van groep {{name}}.",
            addedServices: "Alle applicaties toegevoegd aan groep {{name}}.",
            addedMember: "Gebruiker {{member}} lid gemaakt van groep {{name}}.",
            addedMembers: "Alle gebruikers lid gemaakt van groep {{name}}.",
            deletedMember: "Lid {{member}} verwijderd uit groep {{name}}.",
            deletedMembers: "Leden verwijderd uit groep {{name}}.",
            addedInvitation: "Gebruiker {{member}} is toegevoegd als lid van groep {{name}}.",
            deletedInvitation: "Genodigde {{invitation}} is verwijderd van de groep {{name}}.",
        },
        addAllMembers: "Voeg alle samenwerkingsgebruikers en open staande genodigden toe aan deze groep",
        addAllServices: "Voeg alle samenwerkingsapplicaties toe aan deze groep",
        searchServices: "Zoek, kies en voeg applicaties toe aan groep {{name}}",
        connectedServices: "Applicaties gekoppeld aan {{name}}",
        deleteServiceWarning: "Waarschuwing: ontkoppelen van een applicatie van een groep verwijdert alle applicatiespecifieke informatie van via deze groep gekoppelde leden.",
        deleteServiceTooltip: "Maak deze applicatie onbeschikbaar voor groep {{name}}. <strong>Let op</strong>: de applicatie wordt niet verwijderd, alleen niet meer beschikbaar voor deze groep.",
        searchMembers: "Zoek, kies en voeg leden toe aan groep {{name}}",
        connectedMembers: "Leden van {{name}}",
        deleteMemberWarning: "Waarschuwing: door leden uit de groep te verwijderen, wordt alle applicatiespecifieke informatie van die gebruiker voor dat lidmaatschap en die applicatie verwijderd.",
        deleteMemberTooltip: "Verwijder dit lid uit de groep {{name}}. <strong>Let op</strong>: de gebruiker wordt niet verwijderd, alleen niet langer lid van deze groep.",
        deleteInvitationTooltip: "Verwijder deze uitnodiging uit de groep {{name}}. <strong>Let op</strong>: de uitnodiging wordt niet verwijderd. De genodigde zal niet als lid van deze groep worden toegevoegd als de uitnodiging wordt geaccepteerd.",
        service: {
            actions: "",
            name: "Naam",
            entity_id: "Entity ID",
            description: "Omschrijving"
        },
        member: {
            user__name: "Naam",
            user__email: "E-mail",
            user__uid: "UID",
            role: "Rol",
            created_at: "Sinds",
            actions: ""
        },
        invitation: {
            invitee_email: "E-mail",
            intended_role: "Rol",
            expiry_date: "Einddatum",
            actions: ""
        },
        manageMembers: "Beheer leden"
    },
    aup: {
        hi: "Hallo {{name}},",
        name: "Naam.",
        email: "E-mailadres",
        title: "Acceptable use policy",
        multiple: "Applicatie acceptable use policies",
        disclaimer: "Je moet de <a href=\"{{url}}\" target=\"_blank\">acceptable use policy</a> accepteren.",
        agreeWithTerms: "Hierbij verklaar ik dat ik de acceptable use policy heb gelezen en deze accepteer",
        onward: "Verder",
        agreedFlash: "Je akkoord met {{name}} is opgeslagen.",
        collaboration: {
            title: "Aanvullende voorwaarden voor de acceptable use policy",
            info: "Voor deze samenwerking zijn er enkele aanvullingen op de algemene SURF Research Access Management acceptable use policy. Je moet deze voorwaarden accepteren om lid te kunnen worden van deze samenwerking.",
            agreeWithTerms: "Ik heb deze voorwaarden gelezen en accepteer ze",
        },
        service: {
            title: "Bekijk de informatie om verder te gaan",
            info: "Je staat op het punt om in te loggen op <strong>{{name}}</strong>. Voordat je verder kunt gaan, moet je het beleid voor acceptabel gebruik (AUP) en het privacyverklaring van de applicatie lezen. Bevestig hieronder of je ze accepteert.",
            noPrivacyPolicy: "Geen privacyverklaring verstrekt",
            noAup: "Geen AUP verstrekt",
            noContact: "Geen contact verstrekt",
            agreeWithTerms: "Ik verklaar dat ik deze voorwaarden heb gelezen en accepteer",
            firstLogin: "Inloggen. Daarna kom je hier terug om de voorwaarden van {{name}} te bekijken.",
            login: "Inloggen",
            proceed: "Ga naar {{name}}",
            multipleCollaborations: "Deze applicatie wordt gebruikt binnen meerdere samenwerkingen waarvan je lid bent.",
            purposeOf: "Doel van <strong>{{name}}</strong>",
            informationService: "Informatie over de applicatie",
            informationServiceMultiple: "Informatie over de applicaties",
            contact: "Contact",
            organisationAccess: "Je hebt toegang tot deze applicatie omdat deze door de beheerders van de organisatie beschikbaar is gesteld aan iedereen in je organisatie.",
            missing: {
                info: "De acceptable use policy (AUP) van deze applicatie is gewijzigd. Lees de AUP en geef aan of je akkoord gaat.",
                infoMultiple: "De acceptable use policies (AUPs) van deze applicaties zijn gewijzigd. Lees de AUPs en geef aan of je akkoord gaat."
            },
            singleInfo: "Een applicatie gebruikt binnen deze samenwerking vereist dat je akkoord gaat met een acceptable use policy.",
            multipleInfo: "Applicaties gebruikt binnen deze samenwerking vereisen dat je akkoord gaat met de acceptable use policies.",
            singleCheck: "Ik ga akkoord met de hierboven genoemde acceptable use policy",
            multipleCheck: "Ik ga akkoord met de hierboven genoemde acceptable use policies",
            singleInfoAccepted: "Je bent al akkoord gegaan met de acceptable use policy van de applicatie gebruikt door deze samenwerking.",
            multipleInfoAccepted: "Je bent al akkoord gegaan met de acceptable use policies van de applicaties gebruikt door deze samenwerking.",
        },
        organisation: {
            title: "Organisatie AUP",
            info: "Je bent geen lid van de organisatie <strong>{{name}}</strong>. Voordat je verder kunt gaan, moet je de acceptable use policy (AUP) van de organisatie lezen. Bevestig hieronder of je die accepteert.",
            check: "Ik ga akkoord met de acceptable use policy van deze organisatie"
        }
    },
    collaborationRequest: {
        title: {
            open: "{{requester}} heeft een verzoek gedaan om een nieuwe samenwerking '{{name}}' te maken .",
            approved: "{{requester}} samenwerkingsverzoek is goedgekeurd.",
            denied: "{{requester}} samenwerkingsverzoek is afgewezen."
        },
        request: "Verzoek {{id}}",
        requester: "Aanvrager",
        approved: "Goedgekeurd",
        rejectionReason: "Reden voor afwijzing",
        denyConfirmation: "Weet je zeker dat je dit verzoek wil afkeuren?",
        deleteConfirmation: "Weet je zeker dat je dit verzoek wil verwijderen?",
        approve: "Goedkeuren",
        deny: "Afkeuren",
        status: "Status",
        statuses: {
            all: "Allemaal ({{nbr}})",
            open: "Open",
            denied: "Afgewezen",
            approved: "Goedgekeurd"
        },
        flash: {
            approved: "Samenwerking {{name}} is aangemaakt en het verzoek daartoe is verwijderd",
            denied: "Het verzoek om samenwerking {{name}} aan te maken is afgewezen",
            deleted: "Verzoek voor samenwerking {{name}} is verwijderd",
        }
    },
    serviceConnectionRequest: {
        title: "Applicatie koppelverzoek",
        subTitle: "Verzoek van {{requester}} om toegang voor samenwerking {{collaboration}} voor applicatie {{service}} toe te staan",
        message: " Motivatie",
        messageTooltip: "De motivatie van {{name}} voor dit verzoek",
        service: "Applicatie",
        collaboration: "Samenwerking",
        requester: "Aanvrager",
        decline: "Afwijzen",
        accept: "Accepteren",
        retract: "Intrekken",
        approveConfirmation: "Weet je zeker dat je dit verzoek wil goedkeuren?",
        declineConfirmation: "Weet je zeker dat je dit verzoek wil afwijzen?",
        deleteConfirmation: "Weet je zeker dat je dit verzoek wil verwijderen?",
        flash: {
            declined: "Applicatiekoppelverzoek voor {{name}} is afgewezen",
            accepted: "Applicatiekoppelverzoek voor {{name}} is geaccepteerd",
            deleted: "Applicatiekoppelverzoek voor {{name}} is verwijderd",
            notFound: "Dit verzoek is reeds geaccepteerd/afgewezen."
        }
    },
    autocomplete: {
        name: "Name",
        description: "Omschrijving",
        email: "E-mail",
        admin: "Super user",
        organisations: "Organisaties",
        collaborations: "Samenwerkingen",
        link: "Link",
        noResults: "Geen resultaat",
        resultsLimited: "Meer resultaten dan we kunnen tonen; pas de zoekopdracht aan."
    },
    history: {
        changes: "Geschiedenis",
        detail: "Detail",
        actions: {
            "1": "Nieuwe",
            "2": "Gewijzigd",
            "3": "Verwijderd",
            approved: "Goedgekeurd",
            denied: "Afgewezen"
        },
        overview: "{{action}} {{collection}}{{name}}",
        none: "Geen geschiedenis weer te geven",
        key: "Attribuut",
        oldValue: "Oude waarde",
        newValue: "Nieuwe waarde",
        parentNew: "Nieuwe {{collection}} toegevoegd aan {{parent}}",
        parentUpdated: "Bestaande {{collection}} is aangepast en is gelinkt aan {{parent}}",
        parentDeleted: "Bestaande {{collection}} verwijderd uit {{parent}}",
        activities: {
            tables: "Alle audit-logtabellen",
            tablesTooltip: "Selecteer alle tabellen waarop je wil filteren. Als je niets selecteert dan wordt er niet op tabellen gefilterd.",
            tablesPlaceHolder: "Selecteer tabellen",
            submit: "Haal op"
        },
        export: "Exporteer naar JSON",
        searchPlaceholder: "Zoek...",
        includeServices: "Toon applicaties",
        includeMembers: "Toon leden",
        includeConnections: "Show connections",
        includeProperties: "Toon eigenschappen",
        tables: {
            "api_keys": "Organisatie-API-tokens",
            "aups": "Acceptable User Policy",
            "collaboration_memberships": "Lidmaatschap",
            "collaboration_memberships_groups": "Groepslidmaatschap",
            "collaboration_requests": "Samenwerkingsverzoek",
            "collaborations": "Samenwerking",
            "groups": "Groep",
            "groups_invitations": "Groepsuitnodiging",
            "invitations": "Uitnodiging",
            "join_requests": "Lidmaatschapsverzoek",
            "organisation_invitations": "Organisatieuitnodiging",
            "organisation_memberships": "Organisatielidmaatschap",
            "organisation_aups": "Organisatie AUP",
            "organisations": "Organisatie",
            "organisations_services": "Applicatie",
            "service_connection_requests": "Koppelverzoek",
            "service_groups": "Applicatiegroepen",
            "services": "Applicatie",
            "services_collaborations": "Applicatie",
            "service_requests": "Applicatieregistratie",
            "users": "Gebruiker",
            "ip_networks": "IP-netwerk",
            "suspend_notifications": "Loginnotificatie",
            "schac_home_organisations": "Schac home organisations",
            "user_names_history": "Username history",
            "ssh_keys": "SSH-sleutel",
            "user_mails": "Gebruikers-e-mails",
            "service_aups": "Applicatie-AUP's",
            "user_tokens": "Gebruikerstokens",
            "service_invitations": "Applicatieuitnodiging",
            "service_memberships": "Applicatielidmaatschap",
            "tags": "Label",
            "service_tokens": "Applicatietokens (allemaal)"
        }
    },
    serviceRequest: {
        title: {
            open: "{{requester}} heeft een verzoek gedaan om een nieuwe applicatie '{{name}}' te maken .",
            approved: "{{requester}} applicatieregistratie is goedgekeurd.",
            denied: "{{requester}} applicatieregistratie is afgewezen."
        },
        request: "Verzoek {{id}}",
        requester: "Aanvrager",
        approved: "Goedgekeurd",
        rejectionReason: "Reden voor afwijzing",
        denyConfirmation: "Weet je zeker dat je deze applicatieregistratie wil afkeuren?",
        deleteConfirmation: "Weet je zeker dat je deze applicatieregistratie wil verwijderen?",
        approve: "Goedkeuren",
        deny: "Afkeuren",
        metaData: "De SAML metadata",
        showMetaData: "Toon de SAML metadata",
        status: "Status",
        statuses: {
            all: "Allemaal ({{nbr}})",
            open: "Open",
            denied: "Afgewezen",
            approved: "Goedgekeurd"
        },
        flash: {
            approved: "Applicatie {{name}} is aangemaakt",
            denied: "De applicatieregistratie is afgewezen",
            deleted: "Aanvraag voor registratie van applicatie {{name}} is verwijderd",
        }
    },
    confirmation: {
        title: "Promoveer tot platformbeheerder",
        info: "Je bent geconfigureerd als platformbeheerder, maar je moet dit verifiëren met een sterkere verificatie. Klik op de onderstaande knop om de upgrade van je profiel te starten.",
        confirmAccount: "Start verificatie"
    },
    inputField: {
        fileImport: "Upload",
    },
    confirmationDialog: {
        title: "Bevestiging",
        subTitle: "Deze actie vereist een bevestiging",
        confirm: "Bevestig",
        ok: "OK",
        cancel: "Annuleer",
        leavePage: "Weet je zeker dat je deze pagina wil verlaten?",
        leavePageSub: "Wijzigingen die niet zijn opgeslagen worden niet bewaard.",
        stay: "Blijf",
        leave: "Verlaat deze pagina"
    },
    tabs: {
        invitation_form: "Uitnodigingsdetails",
        invitation_preview: "Uitnodigingspreview",
        form: "Details",
        history: "Geschiedenis"
    },
    error_dialog: {
        title: "Onverwachte fout",
        subTitle: "Er is een fout opgetreden",
        body: "Dit is gênant; er is een onverwachte fout opgetreden. De fout is gerapporteerd. Probeer het nogmaals.",
        deadEnd: "Geen idee waar we vanaf dit punt naartoe kunnen",
        ok: "Sluiten"
    },
    not_found: {
        title: "404",
        description_html: "Deze pagina kan niet worden gevonden.",
        loginLink: "Inloggen"
    },
    footer: {
        support: "Hulp",
        supportLink: "https://edu.nl/vw3jx",
        terms: "Gebruiksvoorwaarden",
        termsLink: "https://edu.nl/6wb63",
        privacy: "Privacyverklaring",
        privacyLink: "https://edu.nl/fcgbd",
        surfLink: "https://surf.nl",
    },
    system: {
        title: "Systeemonderhoud",
        runDailyJobsInfo: "Voer de dagelijkse cron-taak uit om notificatie-e-mails te verzenden en mogelijk gebruikers op te schorten die zich gedurende de geconfigureerde periode niet hebben aangemeld",
        runExpiredCollaborations: "Run the job to expire collaborations that have an expiratiob date before today",
        runExpiredMemberships: "Run the job to expire memberships that have an expiration date before today",
        runSuspendedCollaborations: "Run the job to suspend collaborations that have had no activity for X days",
        runOrphanUsers: "Run the job to delete orphan users (e.g. no membership, join request and collaboration requests)",
        runInvitationReminders: "Run the job to send reminder mails to all invitations that expire in X days",
        runInvitationExpirations: "Run the job to delete invitations that have expired X days",
        runDailyJobs: "Trigger",
        showDailyJobs: "Show",
        showDailyJobsInfo: "Toon alle ingeplande Cron jobs",
        parseMetaData: "Parse",
        showParsedMetaDataInfo: "Parse the IdP MetaData",
        jobName: "Naam",
        jobNextRun: "Geplande run-time",
        runOutdatedRequestsInfo: "Voer de cron-taak uit om alle verouderde open join-verzoeken en nieuwe samenwerkingsverzoeken voor de geconfigureerde drempel te rapporteren",
        runOpenRequestsInfo: "Run the weekly cron job to report all open requests for CO, Org and Application admins",
        runOutdatedRequests: "Run",
        runCleanedRequestsInfo: "Voer de cron-taak uit om alle verouderde goedgekeurde / geweigerde aanmeldingsverzoeken en samenwerkingsverzoeken voor de geconfigureerde drempel te verwijderen",
        runCleanedRequests: "Verwijder",
        runSweepResults: "Voer de cron-taak uit om alle SCIM applicaties te sweepen",
        runSweep: "Run",
        action: "Action",
        results: "Results",
        warning_suspend_notifications: "Waarschuwings e-mail met schorsingsmelding verzonden",
        suspended_notifications: "Gebruikers geschorst en e-mails met schorsingsmelding verzonden",
        warning_deleted_notifications: "Waarschuwings e-mail met verwijdering verzonden",
        deleted_notifications: "Gebruikers verwijderd en e-mails met verwijdering verzonden",
        collaborations_deleted: "Verlopen samenwerkingen verwijderd",
        collaborations_expired: "Verlopen samenwerkingen",
        collaborations_suspended: "Geschorste samenwerkingen",
        collaborations_warned: "Gewaarschuwde beheerders",
        orphan_users: "Verwijderde wees-gebruikers",
        memberships_deleted: "Verwijderde verlopen lidmaatschappen",
        memberships_expired: "Verlopen lidmaatschappen",
        memberships_warned: "Gewaarschuwde leden",
        runDbStatsInfo: "Databasetabellen en -aantallen",
        userLoginInfo: "User login samenvatting (alleen proxy-authz)",
        runDbStats: "Haal op",
        name: "Tabelnaam",
        count: "Aantal records",
        activity: "Alle recente activiteit uit de audit-logs ({{count}})",
        searchPlaceholder: "Zoek in geschiedenis...",
        searchPlaceholderServer: "Zoek in tabel audit_logs (e.g. server side)...",
        runDbSeedConfirmation: "Weet je het zeker? Hiermee worden alle huidige gegevens verwijderd",
        runDbSeedInfo: "Verwijder alle gegevens en voeg de <strong>TEST</strong> gegevens toe",
        runDbDemoSeedInfo: "Verwijder alle gegevens en voeg de <strong>TEST+DEMO</strong> gegevens toe",
        runDbSeed: "Voer uit",
        runClearAuditLogsConfirmation: "Weet je zeker dat je alle gegevens uit de audit-logs wil verwijderen?",
        cleanSlate: "Verwijder alles",
        runCleanSlate: "Weet je zeker dat je alle gegevens wil verwijderen?",
        clear: "Clear",
        reload: "Reload",
        seedResult: "Testgegevens toegevoegd in {{ms}} ms.",
        toggleJson: "Toggle view",
        suspendedUsers: {
            title: "De onderstaande gebruikers zijn geschorst.",
            titleZeroState: "Er zijn geen geschorste gebruikers",
            email: "E-mail",
            name: "Naam",
            lastLogin: "Laatste login",
            activate: "Activeer"
        },
        resetTOTPRequestedUsers: {
            title: "De onderstaande gebruikers hebben een verzoek gedaan om hun TOTP te resetten.",
            titleZeroState: "Er zijn geen gebruikers die een verzoek hebben gedaan.",
            reset: "Reset"
        },
        userlogins: {
            loginType: "Type",
            total: "Logins",
            succeeded: "Succeeded",
            failed: "Failed",
        },
        scim: {
            info: "If enabled there is a mock remote SCIM client available to test the SCIM events." +
                "Configure an application with the following SCIM URL to start testing the SCIM functionality",
            services: "SCIM enabled applicaties",
            stats: "All API calls and state updates send to the mock SCIM endpoints are stored in an in-memory database.",
            retrieveStats: "Fetch stats",
            reRetrieveStats: "Refresh stats",
            clearStats: "Clear stats",
            clearConfirmation: "Are you sure you want to clear the SCIM statistics?",
            service: {
                scimUrl: "Scim URL",
                id: "Id",
                scimEnabled: "Scim enabled",
                sweepScimEnabled: "Sweep enabled",
                sweep: "Sweep",
            }
        },
        stats: {
            loading: "Loading, please wait..."
        },
        activityTable: {
            date: "Date",
            user: "User",
            action: "Action"
        },
        invitationReminders: {
            invitations: "CO invitations resend",
            serviceInvitations: "Application invitations resend",
            organisationInvitations: "ORG invitations resend",
        },
        invitationExpirations: {
            invitations: "Invitations deleted",
            apiInvitations: "API invitations deleted",
            serviceInvitations: "Application invitations deleted",
            organisationInvitations: "Organisation invitations deleted",
        },
        openRequests: {
            recipient: "Recipient",
            service_requests: "Application requests",
            service_connection_requests: "Application connection requests",
            join_requests: "Join requets",
            collaboration_requests: "Collaboration requests",
            collaboration_name: "CO name",
            requester: "Requester",
            organisation_name: "ORG name",
            service_name: "Application name",
        },
        pam: {
            service: "PAM services",
            serviceTooltip: "All PAM-enabled services with a PAM token",
            pamToken: "PAM token",
            pamTokenTooltip: "The raw / unhashed PAM token you just (?) created for the selected service",
            userAttribute: "User attribute",
            userAttributeTooltip: "The user attribute is provided by the PAM web-module to uniquely identify the user by this attribute",
            userIdentifier: "User identifier",
            userIdentifierTooltip: "The user identifier is the value to lookup the user by the chosen User attribute",
            start: "Start",
            reset: "Reset",
            steps: "PAM weblogin steps",
            startSessionResults: "Start PAM session results",
            startSessionResultsCached: "PAM session successfully cached",
            startSessionErrors: "Start PAM session errors",
            sessionResult: "PAM session details",
            pin: "Pin",
            info: "Info",
            pollResult: "Pol session results",
            validatePinResult: "Validate PIN code results",
            memberships: "Memberships"
        }
    },
    access: {
        full: "Volledige",
        limited: "Beperkte",
        readOnly: "Lees-",
        info: "{{access}} rechten als {{role}}",
        platformAdmin: "platformbeheerder",
        orgAdmin: "organisatiebeheerder",
        orgManager: "organisatiemanager",
        coAdmin: "beheerder",
        coMember: "lid",
        serviceAdmin: "applicatiebeheerder",
        serviceManager: "applicatiemanager",
        user: "gebruiker"
    },
    actionRoles: {
        orgAdmin: "Organisatiebeheerder",
        orgManager: "Organisatiemanager",
        coAdmin: "Beheerder",
        coMember: "Lid",
        platformAdmin: "Platformbeheerder",
        user: "Gebruiker",
        serviceAdmin: "Applicatiebeheerder",
        serviceManager: "Applicatiemanager"
    },
    welcomeDialog: {
        title: "Welkom bij {{name}}",
        hi: "Hoi,",
        label: "",
        roleServiceAdmin: "Je bent uitgenodigd om <strong>applicatiebeheerder</strong> te worden",
        roleOrganisationAdmin: "Je bent uitgenodigd om <strong>beheerder</strong> te worden",
        roleOrganisationManager: "Je bent uitgenodigd om <strong>manager</strong> te worden",
        roleCollaborationAdmin: "Je bent uitgenodigd om <strong>beheerder</strong> te worden van deze samenwerking",
        roleCollaborationMember: "Je bent uitgenodigd lid te worden van deze samenwerking",
        ok: "Akkoord, door naar de {{type}}",
        organisation: "organisatie",
        collaboration: "samenwerking",
        service: "applicatie",
        toggleRole: "Wissel van rol",
        infoMember: "Houd er rekening mee dat wanneer je lid wordt van deze samenwerking, je persoonlijke gegevens gedeeld kunnen worden met de volgende applicaties. Neem even de tijd om de door de applicatie opgegeven beleidsdocumenten door te nemen.",
        infoAdmin: "Houd er rekening mee dat wanneer je beheerder wordt van deze samenwerking, je persoonlijke gegevens gedeeld kunnen worden met de volgende applicaties. Neem even de tijd om de door de applicatie opgegeven beleidsdocumenten door te nemen.",
        infoJoinRequest: " Voordat je kunt verzoeken lid te worden van deze samenwerking moet je de acceptable use policy (AUP) en het privacyverklaring van de applicatie lezen. Geef hieronder aan of je deze accepteert.",
        purpose: "Doel van deze samenwerking",
        noServices: "Nog geen applicaties gekoppeld.",
        proceed: "Ga door naar {{name}}"
    },
    welcome: {
        title: "Hallo {{name}},",
        subTitle: "Met <strong>SURF Research Access Management</strong> kun je inloggen op onderzoeksapplicaties en samenwerken met andere onderzoekers.",
        unknown: "een onbekende instelling",
        mysterious: "onbekende gast",
        joining: "Ben je uitgenodigd?",
        invited: "Ben je uitgenodigd voor een samenwerking? Volg de link in de e-mail.",
        creating: "Een nieuwe samenwerking maken",
        institutionCollNotAllowed: "Je bent ingelogd via <strong>{{name}}</strong> waardoor je geen samenwerking kunt aanmaken. Als je dat wel had verwacht, neem dan contact op met <a href=\"mailto:sram-support@surf.nl\">sram-support@surf.nl</a> voor hulp.",
        startCreateColl: "Je bent ingelogd via <strong>{{name}}</strong> waardoor je een samenwerking kunt aanmaken. Daarmee kun je applicaties koppelen en onderzoekers uitnodigen.",
        startRequestColl: "Je bent ingelogd via <strong>{{name}}</strong>, en je kunt hen vragen een samenwerking voor je te maken. Nadat je verzoek is goedgekeurd, kun je applicaties koppelen en onderzoekers uitnodigen.",
        instructions: "Bericht van <strong>{{name}}</strong>",
        createCollTxt: "Samenwerking aanmaken",
        createCollRequestTxt: "Vraag samenwerking aan",
    },
    feedback: {
        title: "Feedback geven",
        info: "Loop je ergens tegenaan? Heb je een suggestie? Laat ons hier weten wat je ervan vindt",
        disclaimer: "We zullen deze informatie gebruiken om problemen op te lossen, ons product te verbeteren en je te helpen. " +
            "We kunnen contact met je opnemen over je feedback. " +
            "Zorg dat je feedback geen vertrouwelijke, gevoelige of persoonlijke informatie bevat. " +
            "Raadpleeg voor meer informatie onze <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"https://edu.nl/fcgbd\">Privacyverklaring</a>.",
        help: "Hulp nodig?",
        helpInfo: "Neem bij vragen of problemen contact met ons op via <a href='mailto:sram-support@surf.nl'> sram-support@surf.nl </a>",
        send: "Feedback geven",
        flash: "Je feedback is verzonden. Bedankt!"
    },
    landing: {
        header: {
            title: "Eenvoudige en veilige toegang tot onderzoeksapplicaties voor onderzoekssamenwerkingen",
            login: "Inloggen",
            sup: "Is SURF Research Access Management nieuw voor je? Log in en start een samenwerking via je instelling.",
        },
        works: "Hoe werkt het?",
        adminFunction: "beheerdersfunctie",
        create: "Aanmaken",
        createInfo: "<p>Beheerders maken een pagina aan voor de onderzoekers van hun samenwerking.</p>" +
            "<p>Vervolgens selecteren zij applicaties uit de catalogus om ze beschikbaar te maken voor de samenwerking.</p>",
        invite: "Uitnodigen",
        inviteInfo: "<p>Beheerders nodigen medeonderzoekers uit voor de samenwerkingspagina.</p>" +
            "<p>Ze kunnen ook leden uitnodigen mee te helpen als beheerder.</p>",
        join: "Meedoen",
        joinInfo: "<p>Uitgenodigde onderzoekers loggen in via hun instelling of eduID.nl.</p>",
        collaborate: "Samenwerken",
        collaborateInfo: "<p>De beveiligde samenwerkingspagina toont de leden en geeft toegang tot de onderzoeksapplicaties.</p>",
        footer: "<p>SURF Research Access Management is een applicatie voor toegangsbeheer van Nederlandse onderzoekssamenwerkingen.</p>" +
            "<p>Wil je meer weten? Bezoek <a href='https://surf.nl/sram'>https://surf.nl/sram</a>.</p>"
    },
    tooltips: {
        members: "Samenstelling van deze samenwerking",
        admins: "Beheerders van deze samenwerking",
        collaborationUrl: "Meer informatie over deze samenwerking",
        aup: "Acceptabel gebruiksbeleid voor deze samenwerking",
        user: "Gebruiker",
        admin: "Beheerder",
        invitations: "Genodigde",
        joinRequest: "Deelnameverzoek",
        manager: "Manager",
        platformAdmin: "Goddess divinity",
        oneAdminWarning: "Een organisatie heeft ten minste 1 admin nodig.",
        serviceGroupConnectedNotDeletable: "Applicatiegroep van een gekoppelde applicatie mag niet worden verwijderd; ontkoppel de applicatie eerst."
    },
    notFound: {
        invitationNotFound: "Deze uitnodiging bestaat niet (meer). Waarschijnlijk is de uitnodiging al eerder gebruikt.</br>" +
            "Vraag aan de uitnodiger of je een nieuwe uitnodiging kunt krijgen.",
        invalidSecondFactorUUID: "De koppeling is verlopen. Ga terug naar de applicatie die je probeerde te bereiken en probeer het opnieuw.",
        invalidPamWebSSO: "Je sessie is verlopen. Ga terug naar de applicatie waar je probeert in te loggen en probeer het opnieuw.",
        msg: "Het spijt ons, deze pagina kunnen we niet vinden.</br></br>" +
            "Probeer opnieuw vanaf de <a href='{{base_url}}'>thuispagina</a> of neem contact met ons op via <a href='mailto:sram-support@surf.nl'>sram-support@surf.nl</a>."
    },
    mfa: {
        register: {
            title: "Tweefactorauthenticatie is vereist",
            titleUpdate: "Voeg een tweefactorauthenticatie-apparaat toe",
            info1: "SURF Research Access Management vereist sinds kort twee-factor-authenticatie. Dat betekent dat je een extra controle dat jij het bent moet configureren.<br/><br/>" +
                "Heb je al een tweede factor gebruikt om je aan te melden bij {{name}}? Neem dan ",
            info11: " zodat we dat kunnen oplossen.",
            contactUs: "contact op",
            info2: "Voer de volgende stappen uit om tweefactorauthenticatie in te stellen:",
            step: "Stap {{nbr}}",
            getApp: "1. Voeg SURF Research Access Management toe aan je authenticator-app",
            unknownIdp: "je instelling",
            unknownFeedbackIdp: "mijn instelling",
            getAppUpdate: "2. Voeg SURF Research Access Management toe aan je authenticator-app",
            new: "nieuw",
            getAppInfo: "Open of installeer de Google (<a href=\"https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=nl&gl=NL\" target=\"_blank\">Android</a> of <a href=\"https://apps.apple.com/nl/app/google-authenticator/id388497605\" target=\"_blank\">iOS</a>) of Microsoft (<a href=\"https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=nl&gl=NL\" target=\"_blank\">Android</a> of <a href=\"https://apps.apple.com/us/app/microsoft-authenticator/id983156458\" target=\"_blank\">iOS</a>) authenticator-app op je {{new}}mobiele apparaat",
            addSRAM: "Klik op het plusje (+) in de app",
            scan: "Scan de QR-code met de camera van je apparaat",
            scanInfo: "Open de authenticator-app en:",
            showSecret: "Toon het gedeelde secret",
            verificationCode: "2. Voer verificatiecode in",
            verificationCodeUpdate: "3. Voer verificatiecode die de app weergeeft in",
            verificationCodeInfo: "Voer verificatiecode die de app weergeeft in",
            verify: "Verifiëren en activeren",
            next: "Volgende",
            feedback: "Ik moest twee-factor-authenticatie configureren bij SURF Research Access Management, maar ik heb al een tweede factor gebruikt om aan te melden bij {{name}}.\n\n" +
                "Bedankt voor je bericht! Je kunt informatie toevoegen, als je wil."
        },
        update: {
            info1: "Volg de onderstaande stappen om je tweefactorauthenticatie bij te werken.",
            info2: "Je moet toegang hebben tot de authenticator-app die je momenteel gebruikt om in te loggen bij SURF Research Access Management, evenals tot de nieuwe authenticator-app die je wil gebruiken voor toekomstige logins.",
            currentCode: "Voer de huidige verificatiecode in",
            currentCodeInfo: "Voer de 6-cijferige verificatiecode van je huidige authenticator-app in.",
            scanInfo: "Open je nieuwe authenticator-app en:",
            verificationCodeInfo: "Zodra de QR-code hierboven is gescand, voer je de 6-cijferige verificatiecode van je nieuwe authenticator-app in.",
            verify: "Verifiëren en wijzigen",
            flash: "Je tweefactorauthenticatie is aangepast voor je nieuwe authenticator-app",
            preValidatedFlash: "Correct!"
        },
        verify: {
            title: "Vul je verificatiecode in",
            info1: "Vul de verificatiecode uit je authenticator-app voor <strong>SURF Research Access Management</strong> in om je identiteit te verifiëren.",
            explanation: "Tweefactor-authenticatie",
            signIn: "Verifiëren",
            problems: "Problemen met inloggen?",
            invalid: "Onjuiste verificatiecode",
            resetRequest: "Reset je verificatiecode",
            resetToken: "Gebruik een resetcode",
            rateLimited: "Je bent rate-limited",
            rateLimitedInfo: "Je hebt de TOTP-verificatie te vaak geprobeerd en daarom is je TOTP uitgeschakeld. Gebruik een resetcode om je TOTP te resetten. Neem contact op sram-support@surf.nl voor een resetcode als je deze niet ontvangen hebt of vraag een nieuwe restcode aan.",
            rateLimitedResetRequest: "Reset je verificatiecode",
            rateLimitedResetToken: "Gebruik een resetcode",
        },
        profile: {
            name: "Tweefactorauthenticatie",
            no2FA: "Tweefactorauthenticatie is niet geconfigureerd.",
            tooltip: "Tweefactorauthenticatie (2FA) voegt een extra beveiligingslaag aan je SURF Research Access Management profiel toe. Het vereist een extra login —meer dan alleen je gebruikersnaam en wachtwoord— om toegang te krijgen tot je profiel, en om die tweede referentie te krijgen, moet je toegang hebben tot je mobiele authenticator-app.",
            handledByIdp: "Tweefactorauthenticatie wordt afgedwongen door {{name}}.",
            institution: "je instelling",
            handledBySRAM: "Tweefactorauthenticatie met authenticator-app voor mobiele telefoon is geconfigureerd.",
            edit: "Aanpassen"
        },
        lost: {
            title: "Vraag een reset code aan",
            how: "Hoe werkt dit",
            info1: "Je moet een beheerder vragen om je verificatie-code te restten",
            info2: "<strong>We zullen ze een email sturen</strong> met je verzoek",
            info3: "Ze zullen bevestigen dat jij jij bent",
            info4: "Ze zullen je een <strong>reset token</strong> sturen",
            info5: "Met dat token kan je je tweefactorauthenticatie opnieuw instellen",
            select: "Selecteer aan wie de reset te vragen:",
            organisationNamePlatformAdmin: "",
            displayNamePlatformAdmin: "SRAM support",
            respondent: "Je verzoek wordt verzonden naar:",
            message: "Bericht voor de beheerder(s)",
            sendMail: "Vraag reset aan",
            flash: "Je verzoek om een reset is verzonden"
        },
        reset: {
            title: "Reset je verificatiecode",
            info1: "Voer je ontvangen resettoken in",
            submit: "Reset",
            resetCodePlaceholder: "Resettoken",
            invalid: "Ongeldig resettoken",
        }
    },
    sfo: {
        title: "Je hebt geen toegang tot <i>{{name}}</i>",
        info: "Pech gehad...",
        info1: "<p>Toegang tot <i>{{name}}</i> vereist lidmaatschap van een samenwerking in SURF Research Access Management.</p><p>Vraag de beheerders om je uit te nodigen voor een samenwerking die toegang geeft tot deze applicatie.</p>",
        info2: "<p>Je profiel in SURF Research Access Management is geschorst. Daarom heb je geen toegang tot <i>{{name}}</i>.</p><p>Neem contact op met de beheerders voor toegang tot deze applicatie.</p>",
        info3: "Je bent ingelogd bij, maar de applicatie <i>{{name}}</i> is onbekend bij SURF Research Access Management.",
        info4: "<p>Je bent ingelogd via SURF Research Access Management, maar geen van de samenwerking(en) waar je lid van bent geven toegang tot <i>{{name}}</i>.</p><p>Neem contact op met de beheerders voor toegang tot deze applicatie.</p>",
        info5: "<p>Je bent ingelogd via SURF Research Access Management, maar je hebt geen toegang tot <i>{{name}}</i> omdat de samenwerking(en) waarvan je lid bent niet actief zijn.</p><p>Neem contact op met de beheerders van jouw samenwerking(en) voor toegang tot deze applicatie.</p>",
        info6: "<p>Je bent ingelogd via SURF Research Access Management, maar je hebt geen toegang tot <i>{{name}}</i>. Je lidmaatschap van de samenwerking(en) waar je momenteel lid van bent zijn verlopen.</p><p>Neem contact op met de beheerders van jouw samenwerking(en) voor toegang tot deze applicatie.</p>",
        ticket: "Als je wil, kun je contact opnemen met SRAM support " +
            "<a href='mailto:sram-support@surf.nl?subject={{subject}}&body=Entity ID: {{entityId}}%0D%0AIssuer ID: {{issuerId}}%0D%0AUser ID: {{userId}}%0D%0ATimestamp: {{timestamp}}'>sram-support@surf.nl</a> voor hulp.",
        subject: "Geen toegang tot applicatie {{name}}",
        entityId: "Entity ID",
        issuerId: "Issuer ID",
        userId: "User ID",
        timestamp: "Timestamp"
    },
    unsuspend: {
        confirmation: "Weet je zeker dat je de schorsing van deze samenwerking ongedaan wil maken?",
        flash: "Samenwerking {{name}} is weer actief"
    },
    resetActivity: {
        confirmation: "Weet je zeker dat je de datum van de laatste activiteit van deze samenwerking wilt resetten?",
        flash: "Collaboration {{name}} laatste activiteit datum is gereset."
    },
    activate: {
        confirmation: "Weet je zeker dat je de verloopdatum van deze samenwerking wilt verwijderen?",
        flash: "Collaboration {{name}} verloopdatum is verwijderd."
    },
    organisationMembership: {
        membership: "Lidmaatschap van {{name}} heeft geen einddatum.",
        membershipWithExpiry: "Lidmaatschap van {{name}} verloopt op {{date}}.",
        expiredMembership: "Dit lidmaatschap is verlopen op {{date}}. Pas de einddatum van het lidmaatschap aan om {{name}} weer lid te maken.",
        member: "Lidmaatschap",
        expiryDate: "Einddatum",
        expiryDateTooltip: "De einddatum van dit lidmaatschap. Hierna verloopt het lidmaatschap en kan de gebruiker geen gebruikmaken van de applicaties van deze samenwerking.",
        update: "Opslaan",
        alreadyMember: "je bent al een lid van deze samenwerking en je hoeft geen nieuwe uitnodiging te accepteren.",
        alreadyMemberHeader: "Reeds lid",
        status: {
            name: "Je lidmaatschap",
            active: "Actief",
            expired: "Lidmaatschap verlopen",
            activeWithExpiryDate: "Verloopt op {{date}}",
            activeTooltip: "Je lidmaatschap heeft geen einddatum.",
            expiredTooltip: "Je lidmaatschap is verlopen op {{date}}. ",
            expiredTooltipMember: "Je lidmaatschap is verlopen op {{date}}. Neem contact op met de beheerders van deze samenwerking om dit te verhelpen. ",
            activeWithExpiryDateTooltip: "je lidmaatschap is actief, maar verloopt op {{date}}. "
        }
    },
    serviceDetail: {
        deleteMemberConfirmation: "Weet je zeker dat je alle geselecteerde leden en uitnodigingen wil verwijderen?",
        deleteYourselfMemberConfirmation: "Weet je zeker dat je deze applicatie wil verlaten? Je kan dit niet terugdraaien.",
        resendInvitations: "Weet je zeker dat je alle geselecteerde uitnodigingen opnieuw wil versturen?",
        downgradeYourselfMemberConfirmation: "Weet je zeker dat je geen beheerder meer wil zijn? Je kan dit niet terugdraaien.",
        flash: {
            entitiesDeleted: "Leden en uitnodigingen zijn verwijderd",
            invitesResend: "Uitnodigingen voor applicatie {{name}} zijn opnieuw verzonden.",
            memberUpdated: "De rol of lidmaatschap van {{name}} is bijgewerkt naar {{role}}.",
        },
        gone: {
            member: "Dit lidmaatschap bestaat niet meer. Na het sluiten van deze pop-up worden de lidmaatschappen vernieuwd.",
            invitation: "Deze uitnodiging is al geaccepteerd/afgewezen en bestaat niet meer. Na het sluiten van deze pop-up worden de uitnodigingen ververst."
        },
        admin: "Applicatiebeheerder",
        manager: "Applicatiemanager",
        intendedRole: "Rol binnen de applicatie",
        intendedRoleTooltip: "De applicatiemanager rol is gelimiteerd tot het beheren van koppelingen met samenwerkingen"
    },
    scim: {
        scimEnabled: "SCIM push naar applicatie",
        scimDisclaimer: "SCIM push naar applicatie is uitgeschakeld",
        scimEnabledTooltip: "Stuur SCIM events aaar deze applicatie",
        scimClientEnabled: "SCIM pull door applicatie",
        scimClientDisclaimer: "SCIM pull door applicatie is uitgeschakeld",
        scimClientEnabledTooltip: "Deze applicatie mag de SCIM endpoints aanroepen",
        scimURL: "SCIM-endpoints base URL",
        scimURLPlaceHolder: "https://applicatie.com.scim",
        scimURLTooltip: "De base URL van het SCIM endpoint waarnaar alle SCIM event worden verzonden.",
        scimBearerToken: "SCIM token",
        scimBearerTokenTooltip: "Het externe SCIM token om de SRAM calls naar de SCIM endpoints te autoriseren door bearer autorisatie",
        sweepScimEnabled: "SCIM sweeping",
        sweepScimEnabledTooltip: "Een periodieke controle en, indien nodig, aanpassing om de data bij de applicatie actueel te houden.",
        scimSweepDeleteOrphans: "Sweep verwijdert weesgebruikers en lege samenwerkingen en groepen",
        scimSweepDeleteOrphansTooltip: "Verwijder met een sweep gebruikers zonder lidmaatschappen en samenwerkingen en groepen zonder leden",
        sweepScimDailyRate: "Sweeps per dag",
        sweepScimDailyRateTooltip: "Hoe vaakt binnen 24 uur de SCIM data op de applicatie gesweept wordt"
    },
    userTokens: {
        actionTitle: "Nieuwe API-token",
        tokens: "Tokens",
        tokenEnabled: "Gebruikertoken introspectie",
        tokenEnabledTooltip: "Deze applicatie mag het introspection endpoint aanroepen om gebruikersinformatie te ontvangen",
        userTokenDisclaimer: "User token introspection is uitgeschakeld",
        pamWebSSOEnabled: "PAM web login",
        pamWebSSOEnabledTooltip: "Deze applicatie mag de PAM web login endpoints aanroepen om een gebruiker in te loggen op een op een terminalgebaseerde app (bijvoorbeeld een SSH-client)",
        tokenValue: "One-way gehashte token",
        tokenValidityDays: "Geldigheidsduur gebruikertoken (dagen)",
        tokenValidityDaysTooltip: "Het aantal dagen dat de gebruikerstokens geldig zijn",
        introspectionEndpoint: "Introspection endpoint URL",
        introspectionEndpointToken: "Introspection endpoint token",
        tokenDisclaimer: "Kopieer de token en sla het veilig op. Je kunt deze token hier maar één keer bekijken.<br><br>Nadat je op 'Toevoegen' hebt gedrukt, kun je het niet meer zien, anders dan door het te verwijderen en opnieuw een nieuw token aan te maken.",
        delete: "Verwijder token",
        deleteConfirmation: "Weet je zeker dat je dit token wil verwijderen? Dit kan niet teruggedraaid worden.",
        flash: {
            deleted: "Het token {{name}} is verwijderd",
            reactivated: "Het token {{name}} is geheractiveerd",
            created: "Token {{name}} toegevoegd",
            updated: "Token {{name}} aangepast",
        },
        reset: {
            confirmation: "Weet je zeker dat je de token voor {{name}} wil resetten?",
            success: "Token is gereset.",
            info: "Kopieer en bewaar de token op een veilige plaats. Je kunt deze token hierna niet meer zien.",
            copy: "Copy",
            close: "Sluit",
        }
    },
    pamWebSSO: {
        title: "Log in op {{service}}",
        info: "Log in voor de verificatiecode die gevraagd wordt op de command line interface.",
        proceed: "Inloggen",
        enterPin: "Je verificatiecode",
        enterPinInfo: "Voer de code in op de command line interface van {{service}}.",
        success: "Helemaal klaar",
        successInfo: "Je kan dit scherm sluiten.",
        timeOut: "Je sessie is verlopen",
        timeOutInfo: " Ga terug naar de applicatie waar je probeert in te loggen en probeer het opnieuw.",
        denied: "Toegang geweigerd",
        deniedInfo: "Helaas heb je geen toegang tot de deze applicatie.",
        contact: "Neem contact op met <a href='mailto:{{support}}'>{{support}}</a> voor toegang tot deze applicatie."
    },
    serviceDetails: {
        details: "Applicatiegegevens",
        tokens: "Tokens",
        tokensTooltips: {
            scim: "Een SCIM token stelt de applicatie in staat om de SCIM endpoints van SRAM aan te roepen",
            pam: "Een PAM web login token stelt de applicatie in staat om de PAM web login-functionaliteit van SRAM te gebruiken",
            introspection: "Een user introspection token stelt de applicatie in staat om tokens van gebruikers te introspecten"
        },
        hashedToken: "Hashed token",
        tokenValue: "**** **** ****",
        tokenType: "Type",
        tokenTypeTooltip: "Je kan dit applicatietoken alleen gebruiken voor {{tokenType}} verzoeken",
        tokenDeleteConfirmation: "Weet je zeker dat je dit applicatietoken wilt verwijderen?",
        disableTokenConfirmation: "Weet je zeker dat je de {{type}} functionaliteit voor deze applicatie wilt uitzetten? {{count}} bestaande {{tokens}} zal worden verwijderd.",
        multipleTokens: "tokens",
        singleTokens: "token",
        noTokens: "Er zijn geen tokens",
        enableTokens: "Om tokens aan te maken, moet {{action}} worden aangezet",
        secretDisclaimer: "Het token wordt slechts eenmaal getoond. Sla de sleutel op en bewaar deze veilig.<br><br>Bij verlies zal je de sleutel moeten verwijderen en een nieuwe moeten aanmaken.",
        secret: "Token",
        secretValue: "One-way hashed token",
        secretTooltip: "Het token voor in de authorization header",
        description: "Omschrijving",
        descriptionPlaceHolder: "Omschrijving voor dit token",
        descriptionTooltip: "Een optionele omschrijving van het doel van dit token",
        backToApiKeys: "Terug naar tokens",
        addToken: "+ Voeg token toe",
        toc: {
            general: "Algemeen",
            contacts: "Contact",
            policy: "Policy",
            ldap: "LDAP",
            tokens: "User introspection tokens",
            pamWebLogin: "PAM web login",
            SCIMServer: "SCIM push naar applicatie",
            SCIMClient: "SCIM pull door applicatie",
            OIDC: "OpenID Connect",
            SAML: "SAML2.0",
            Export: "Export"
        },
        updateDisabled: "Er zijn velden met ongeldige of ontbrekende waarden in de secties {{invalid}}."
    },
    expirations: {
        expires: "Verloopt {{relativeTime}}",
        expired: "Verlopen {{relativeTime}}",
        never: "Verloopt nooit",
        activity: {
            now: "Zojuist",
            seconds: "Vandaag",
            minute: "Vandaag",
            minutes: "Vandaag",
            hour: "Vandaag",
            hours: "Vandaag",
            day: "Gisteren",
            days: "Deze week",
            week: "Deze week",
            weeks: "Deze maand",
            month: "Afgelopen maand",
            months: "%s maanden geleden",
            year: "1 jaar geleden",
            years: "%s jaar geleden"
        },
        ago: {
            now: "zojuist",
            seconds: "%s seconden geleden",
            minute: "1 minuut geleden",
            minutes: "%s minuten geleden",
            hour: "1 uur geleden",
            hours: "%s uren geleden",
            day: "1 dag geleden",
            days: "%s dagen geleden",
            week: "1 week geleden",
            weeks: "%s weken geleden",
            month: "1 maand geleden",
            months: "%s maanden geleden",
            year: "1 jaar geleden",
            years: "%s jaar geleden"
        },
        in: {
            now: "nu",
            seconds: "over %s seconden",
            minute: "over 1 minuut",
            minutes: "over %s minuten",
            hour: "over 1 uur",
            hours: "over %s uren",
            day: "over 1 dag",
            days: "over %s dagen",
            week: "over 1 week",
            weeks: "over %s weken",
            month: "over 1 maand",
            months: "over %s maanden",
            year: "over 1 jaar",
            years: "over %s jaar"
        }
    },
    error: {
        message: "Het spijt ons, we zijn tegen een onbekende fout aangelopen.</br>" +
            "Probeer opnieuw vanaf de <a href='/'>thuispagina</a> of neem contact met ons op via <a href='mailto:sram-support@surf.nl'>sram-support@surf.nl</a>.",
        ssid_failed: "SURF Research Access Management vereist twee-factor-authenticatie, een extra controle dat jij het bent.</br>" +
            "Helaas is deze authenticatie door SURFsecureID zojuist mislukt.</br></br>" +
            "Kopieer en plak de volgende informatie en neem contact op met SRAM support op <a href='mailto:sram-support@surf.nl'>sram-support@surf.nl</a> voor hulp.",
        defaultCode: "urn:oasis:names:tc:SAML:2.0:status:Responder",
        defaultMessage: ""
    },
    gallery: {
        upload: "Upload je eigen logo",
        select: "Of selecteer een logo"
    },
    missingAttributes: {
        info: "Er is iets misgegaan, niet alle inloginformatie is goed doorgekomen. Probeer nogmaals in te loggen, zo mogelijk via een incognito browser venster.",
        contact: "Als het probleem zich nogmaals voordoet, vraag ons dan om hulp via <a href='mailto:sram-support@surf.nl'>sram-support@surf.nl</a>"
    },
    coPageHeaders: {
        membersGroups: "{{memberCount}} {{members}} & {{groupCount}} {{groups}}",
        singleMember: " lid ",
        multipleMembers: " leden ",
        singleGroup: " groep ",
        multipleGroups: " groepen ",
        no: "Geen",
        visit: "Bezoek website",
        membership: "Lid sinds {{date}}",
        expiresTooltip: "Lidmaatschap verloopt op {{date}}"
    },
    servicePageHeaders: {
        notConnected: "<span class='not-connected'>Niet gekoppeld</span> aan een samenwerking",
        allConnected: "Gekoppeld aan <span class='all-connected'>alle</span> samenwerkingen",
        connectedToSingle: "Gekoppeld aan {{count}} samenwerking",
        connectedToMultiple: "Gekoppeld aan {{count}} samenwerkingen",
        notAvailable: " omdat <span class='not-available'>geen enkele organisatie</span> mag koppelen",
        availableSingle: " & beschikbaar voor {{count}} organisatie",
        availableMultiple: " & beschikbaar voor {{count}} organisaties",
        all: "alle",
        launch: "Open de applicatie",
        visit: "bezoek de website"
    },
    myRequests: {
        requestType: "Aanvraag type",
        types: {
            joinRequest: "Lidmaatschapsverzoek",
            collaborationRequest: "Nieuw samenwerkingsverzoek",
            serviceRequest: "Applicatieregistratie",
            serviceConnectionRequest: "Applicatiekoppelverzoek"
        },
        name: "Naam",
        description: "Omschrijving",
        organisationName: "Organisatie / instelling",
        notApplicable: "N/A"
    },
    units: {
        column: "Units",
        label: "Units (bijv. afdeling of project)",
        add: "+ Voeg unit toe",
        confirmation: "Weet je zeker dat je unit {{name}} wilt verwijderen?",
        used: "Het wordt gebruikt door:",
        collaboration_requests: "Samenwerkingsverzoeken",
        invitations: "Organisatie-uitnodigingen",
        collaborations: "Samenwerkingen",
        organisation_memberships: "Lidmaatschappen",
        collaboration: "Relevante units (bijv. afdeling of project)",
        unitsPlaceHolder: "Selecteer een unit",
        collaborationRequest: "Organisatieunits (optioneel)",
        filter: "Alle units",
        invitationLabel: "Managet samenwerkingen van de units",
        invitationTooltip: "Selecteer de organisatieunits waarvoor de organisatiemanager verantwoordelijk is",
        all: "Alle units",
        specify: "Kies units",
        back: "Terug naar alle admins",
        editRole: "Wijzig details organisatiemanager",
        duplicated: "Er is al een unit met de naam {{name}} voor deze organisatie",
        unitRequired: "Je moet minstens één unit selecteren"
    },
    serviceDenied: {
        invitationsTitle: "Uitnodigingen en accounts",
        invitationsSubTitle: "Toegang tot <i>{{serviceName}}</i> wordt toegekend op basis van het lidmaatschap van een samenwerking.",
        invitationsBullets: {
            invited : "Ben je uitgenodigd? Gebruik dan de link in de e-mail.",
            login: "Je bent ingelogd via <strong>{{schacHome}}</strong>. Heb je wellicht ook toegang met een ander account?"
        },
        gainingAccessTitle: "Toegang krijgen",
        gainingAccessBullets: {
            pointed: "Als iemand je hebt gewezen op <strong>{{serviceName}}</strong>, vraag diegene dan om hulp.",
            request: "Je kan een samenwerking {{action}} via <strong>{{schacHome}}</strong> en de applicatie koppelen.",
            contact: "Je kan {{contact}} opnemen met de applicatie admin, zij kunnen je helpen."
        },
        create: "<a href='/new-collaboration'>aanmaken</a>",
        request: "<a href='/new-collaboration'>aanvragen</a>",
        contactUrl: "<a target=\"_blank\" rel=\"noopener noreferrer\" href=\"{{supportEmail}}\">contact</a>.",
        contactEmail: "<a href='mailto:{{supportEmail}}?subject=Toegang aanvragen voor {{serviceName}}&body=Hi,%0D%0A%0D%0AKunt u me helpen om toegang te krijgen tot {{serviceName}}.%0D%0ANaam: {{userName}}%0D%0AEmail: {{userEmail}}%0D%0AOrganisatie: {{schacHome}}%0D%0AReason:%0D%0A%0D%0ABedankt!'>contact</a>",
        createCollaboration: "Samenwerking aanmaken",
        requestCollaboration: "Samenwerking aanvragen",
        requestAccess: "Toegang aanvragen",
        supportTitle: "Krijg ondersteuning",
        supportSubTitle: "Zorg ervoor dat je onderstaande informatie vermeldt.",
        supportBullets: {
            solutions: "Als je de bovenstaande oplossingen al hebt geprobeerd, of denkt dat je al toegang zou moeten hebben, kan je contact opnemen met SRAM-ondersteuning voor hulp via " +
                "<a href='mailto:sram-support@surf.nl?subject={{subject}}&body=Entity ID: {{entityId}}%0D%0AIssuer ID: {{issuerId}}%0D%0AUser ID: {{userId}}%0D%0ATimestamp: {{timestamp}}'>sram-support@surf.nl</a>."
        }
    },
    collaborationsOverview: {
        welcome: "Welkom {{name}}",
        select: "Selecteer je samenwerking",
        request: "Verzoek nieuwe samenwerking",
        create: "Nieuwe samenwerking aanmaken",
        viewRequests: "bekijk verzoeken",
        open: "Open"
    },
    countDownDialog: {
        title: "Toegang tot {{name}}",
        subTitle: "Om toegang te krijgen tot de applicatie {{name}} moet je worden geadministreerd. Dit duurt helaas even, neem een kop ☕️.",
        info: "Over <span class='counter'>{{counter}}</span> seconden zal je worden doorgestuurd."
    },
    collaborationInvitations: {
        inviteWithLink: "Uitnodigen met link",
        inviteWithLinkToggle: "Of nodig gebruikers uit met een link",
        inviteWithLinkInfo: "Deel de link waarmee lidmaatschap aangevraagdkan worden. Je kunt deze verzoeken goed- of afkeuren.",
        inviteWithLinkCopy: "Kopiëer koppeling",
        inviteWithEmail: "Uitnodigen via e-mail",
        inviteWithEmailInfo: "Verstuur een uitnodiging naar éém of meerder e-mailadressen. Met de link in de e-mail kan een gebruiker direct lid worden.",
    }
};

export default nl;
